import React, { useState, useEffect  } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import ProductH from "../../../images/product/h.png";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Spinner,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  PaginationComponent,
  PreviewAltCard,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import FormModal from "./FormModal";
import DeleteModal from "./DeleteModal";
import moment from 'moment';

import { PartnerService } from "../../../_services/partner.service";
import PartnerDetails from "./PartnerDetails";

export const PartnerList = () => {
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    delete :false,
    details: false,
  });

  const [imagePreview, setImagePreview] = useState('');
 


  const [editId, setEditedId] = useState();
  const [deleteId, setDeletedId] = useState();
  const [viewId, setViewId] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [GridLoading, setGridLoading] = useState(false)

  const getAll = () => {
    setGridLoading(true)
    PartnerService.getAll()
      .then(res => {
        setData(res.data)
        setFilteredData(res.data);
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setGridLoading(false)
      })
  }
  useEffect(() => {
    getAll()
  }, [])
const [formData, setFormData] = useState({
  name_en: "",
  name_fr: "",
  name_ar: "",
  logo: null,
  url: "",
  priority: null
});

const [editFormData, setEditFormData] = useState({
  name_en: "",
  name_fr: "",
  name_ar: "",
  logo: null,
  url: "",
  priority: null
});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name_en: "",
      name_fr: "",
      name_ar: "",
      logo: null,
      url: "",
      priority: null
    });
  };
  const [previousImageURL, setPreviousImageURL] = useState('');

  const closeModal = () => {
    setModal({ add: false })
    resetForm();
    setPreviousImageURL("")
    setImagePreview("")

  };

  const closeEditModal = () => {
    setModal({ edit: false })
    resetForm();
    setImagePreview("")
    setPreviousImageURL("")
  };
  const closeDeleteModal = () => {
    setModal({ delete: false })
    resetForm();
    setImagePreview("")
  };

  const [ErreurPriority, setErreurPriority] = useState('');
  const [loadings, setLoadings] = useState(false)

  // submit function to add a new item
  const onFormSubmit = () => {    
    setLoadings(true); 

    const { name_en, name_fr, name_ar, url, priority, logo } = formData;


    const formDataToSend = new FormData();
    formDataToSend.append('name_en', name_en);
    formDataToSend.append('name_fr', name_fr);
    formDataToSend.append('name_ar', name_ar);
    formDataToSend.append('url', url);
    formDataToSend.append('priority', priority);
    if (logo) {
        formDataToSend.append('logo', logo);
    }

    PartnerService.create(formDataToSend)
        .then(res => {
            getAll();
            resetForm();
            setModal({ add: false });
            setImagePreview("")
            setPreviousImageURL("")

        })
        .catch(error => {
            console.error('Erreur lors de la soumission de partner :', error);
            setErreurPriority(error)
        })
        .finally(() => {
          setLoadings(false); 
        });
};


  // submit function to update a new item
  const onEditSubmit = () => {
    setGridLoading(true);
  
    const {
      name_en, name_fr, name_ar, url, priority, logo} = editFormData;
  

    const formDataToSend = new FormData();
    formDataToSend.append('name_en', name_en);
    formDataToSend.append('name_fr', name_fr);
    formDataToSend.append('name_ar', name_ar);
    formDataToSend.append('url', url);
    formDataToSend.append('priority', priority);
  
    if (logo && logo instanceof File) {
      formDataToSend.append('logo', logo);
    }
  
    PartnerService.update(editId, formDataToSend)
      .then(res => {
        console.log('Partner mise à jour avec succès !', res);
        getAll();
        setModal({ edit: false });
        resetForm();
        setImagePreview("")
        setPreviousImageURL("")

      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour de Partner :', error);
        setErreurPriority(error)
      })
      .finally(() => {
        setGridLoading(false);
      });
  };
  



  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setPreviousImageURL(item.logo);
        setEditFormData({
          name_en: item.name_en,
          name_fr: item.name_fr,
          name_ar: item.name_ar,
          url: item.url,
          priority: item.priority,
          logo:item.logo,
        });
        setEditedId(id);
        setModal({ edit: true, add: false });
      }
    });
  };
  

  const onDeleteClick = (id) => {
    setModal({ delete: true }, { add: false },{ edit: false },{details : false });
    setDeletedId(id);
  };

  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(false);

  const onViewClick = (id) => {
    setModal({details : true },{ delete: false }, { add: false },{ edit: false });
    setLoading(true);
    PartnerService.getById(id)
      .then((res) => {
        setPartner(res.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des détails de partner:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const confirmDelete = () => {
    setGridLoading(true); 
    
    PartnerService.deletePartner(deleteId)
      .then(() => {
        getAll(); 
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de la Partner :', error);
      })
      .finally(() => {
        setGridLoading(false); 
        closeDeleteModal(); 
      });
  };
  

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle search
  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    setGridLoading(true); 
    
    const filtered = data.filter(item =>
      item.name_fr.toLowerCase().includes(keyword) ||
      item.url.toLowerCase().includes(keyword) ||
      item.priority.toLowerCase().includes(keyword) ||
      moment(item.created_at).format('DD MMM YYYY').toLowerCase().includes(keyword)
    );
    setFilteredData(filtered);
    
    setGridLoading(false); 
  };


  return (
    <React.Fragment>
      <Head title="Partner List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> Liste des partenaires</BlockTitle>
              <BlockDes className="text-soft">Vous avez au total {data.length} partenaires.</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                  <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="search"
                          // onChange={(e) => onFilterChange(e)}
                          onChange={handleSearch}
                        />
                      </div>
                    </li>
                    <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                      <Button color="primary">
                        <Icon name="plus"></Icon>
                        <span>Ajouter des Partner</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="nk-tb-list is-separate nk-tb-ulist">
            <DataTableHead className="nk-tb-item nk-tb-head">
              <DataTableRow>
                <span className="sub-text">Image</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Nom (fr)</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Url</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Priority </span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Créé à</span>
              </DataTableRow>
              
              <DataTableRow className="nk-tb-col-tools text-end">
              <span className="sub-text">Action</span>
              </DataTableRow>
            </DataTableHead>
            {GridLoading ? (
                <DataTableItem className={'text-center height-100'}>
                  <DataTableRow className={'spinner-container'}>
                    <Spinner color="primary" />
                  </DataTableRow>
                </DataTableItem>
            ) : 
            currentItems.length > 0
              ? currentItems.map((item) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow size="sm">
                        <span className="tb-product">
                          <img src={item.logo ? item.logo : ProductH} alt="partner" className="thumb" />
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{item?.name_fr || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                         <span>{item?.url || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                         <span>{item?.priority || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="mb">
                        <span>{item?.created_at ? moment(item.created_at).format('DD MMM YYYY') : '--'}</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#view"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      onViewClick(item.id);
                                    }}
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>Voir Partner </span>
                                  </DropdownItem>
                                </li>
                                  <li onClick={() => onEditClick(item.id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Modifier</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => onDeleteClick(item.id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#delete"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                     <Icon name="trash"></Icon>
                                      <span>Supprimer</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              : null}
          </div>
          <PreviewAltCard>
            {filteredData.length ||data.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">Aucune Partner trouvée</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>


        <FormModal modal={modal.add} modalType="add" formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit}  setImagePreview={setImagePreview} imagePreview={imagePreview} previousImageURL={previousImageURL} GridLoading={loadings} ErreurPriority={ErreurPriority} />
        <FormModal modal={modal.edit} modalType="edit" formData={editFormData} setFormData={setEditFormData} closeModal={closeEditModal} onSubmit={onEditSubmit}  setImagePreview={setImagePreview} imagePreview={imagePreview} previousImageURL={previousImageURL} GridLoading={GridLoading} ErreurPriority={ErreurPriority} />
        <DeleteModal modal={modal.delete}  closeModal={closeDeleteModal} onSubmit={confirmDelete} GridLoading={GridLoading} />
        <PartnerDetails modal={modal.details}  closeModal={closeDeleteModal} partner={partner} loading={loading} />
      </Content>
      
    </React.Fragment>

    
  );
};

export default PartnerList;