import React, { useState, useEffect  } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Badge,
  Spinner,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  PaginationComponent,
  PreviewAltCard,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import FormModal from "./FormModal";
import DeleteModal from "./DeleteModal";
import moment from 'moment';

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { convertFromHTML, ContentState } from 'draft-js';
import { CategoryDonService } from "../../../_services/categorydon.service ";
import { AdvertisementDonService } from "../../../_services/advertisementdon.service";
import AdvertisementsDonDetails from "./AdvertisementsDonDetails";
import ProductH from "../../../images/product/h.png";

export const AdvertisementsDonList = () => {
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    delete :false,
    details: false,
  });

  const [imagePreview, setImagePreview] = useState('');
 
  
  const [category, setCategory] = useState([]);

  const getCategories = () => {
    setGridLoading(true)
    CategoryDonService.getAllCategories()
      .then(res => {
        setCategory(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setGridLoading(false)
      })
  }
  useEffect(() => {
    getCategories()
  }, [])

  const categoryOptions = category?.map(cat => ({
    value: cat.id,
    label: cat.name_en
  }));



  const [editId, setEditedId] = useState();
  const [deleteId, setDeletedId] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [GridLoading, setGridLoading] = useState(false)

  const getAll = () => {
    setGridLoading(true)
    AdvertisementDonService.getAll()
      .then(res => {
        setData(res.data)
        setFilteredData(res.data);
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setGridLoading(false)
      })
  }
  useEffect(() => {
    getAll()
  }, [])
const [formData, setFormData] = useState({
  title_en: "",
  title_fr: "",
  title_ar: "",
  description_en: EditorState.createEmpty(),
  description_fr: EditorState.createEmpty(),
  description_ar: EditorState.createEmpty(),
  image: null,
  short_description_en: "",
  short_description_fr: "",
  short_description_ar: "",
  donation_needed:0,
  donation_made:0,
  is_urgent: 0,
  seo_title_en: "",
  seo_title_fr: "",
  seo_title_ar: "",
  seo_description_en: "",
  seo_description_fr: "",
  seo_description_ar: "",
  seo_keywords: "",
  category_id: null
});

const [editFormData, setEditFormData] = useState({
  title_en: "",
  title_fr: "",
  title_ar: "",
  description_en: EditorState.createEmpty(),
  description_fr: EditorState.createEmpty(),
  description_ar: EditorState.createEmpty(),
  image: null,
  short_description_en: "",
  short_description_fr: "",
  short_description_ar: "",
  donation_needed:0,
  donation_made:0,
  is_urgent: 0,
  seo_title_en: "",
  seo_title_fr: "",
  seo_title_ar: "",
  seo_description_en: "",
  seo_description_fr: "",
  seo_description_ar: "",
  seo_keywords: "",
  category_id: null
});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);

  // function to reset the form
  const resetForm = () => {
    setFormData({
      title_en: "",
      title_fr: "",
      title_ar: "",
      description_en: EditorState.createEmpty(),
      description_fr: EditorState.createEmpty(),
      description_ar: EditorState.createEmpty(),
      image: null,
      short_description_en: "",
      short_description_fr: "",
      short_description_ar: "",
      donation_needed:0,
      donation_made:0,
      is_urgent: 0,
      seo_title_en: "",
      seo_title_fr: "",
      seo_title_ar: "",
      seo_description_en: "",
      seo_description_fr: "",
      seo_description_ar: "",
      seo_keywords: "",
      category_id: null
    });
  };
  const [previousImageURL, setPreviousImageURL] = useState('');

  const closeModal = () => {
    setModal({ add: false })
    resetForm();
    setPreviousImageURL("")
    setImagePreview("")

  };

  const closeEditModal = () => {
    setModal({ edit: false })
    resetForm();
    setImagePreview("")
    setPreviousImageURL("")
  };
  const closeDeleteModal = () => {
    setModal({ delete: false })
    resetForm();
    setImagePreview("")
  };
  // submit function to add a new item
  const onFormSubmit = () => {    
    setGridLoading(true); 

    const { title_en, title_fr, title_ar,donation_made,donation_needed, description_en, description_fr, description_ar, short_description_en, short_description_fr, short_description_ar,is_urgent, seo_title_en, seo_title_fr, seo_title_ar, seo_description_en, seo_description_fr, seo_description_ar, seo_keywords, category_id, image } = formData;

    const description_en_html = draftToHtml(convertToRaw(description_en.getCurrentContent()));
    const description_fr_html = draftToHtml(convertToRaw(description_fr.getCurrentContent()));
    const description_ar_html = draftToHtml(convertToRaw(description_ar.getCurrentContent()));

    const formDataToSend = new FormData();
    formDataToSend.append('title_en', title_en);
    formDataToSend.append('title_fr', title_fr);
    formDataToSend.append('title_ar', title_ar);
    formDataToSend.append('description_en', description_en_html);
    formDataToSend.append('description_fr', description_fr_html);
    formDataToSend.append('description_ar', description_ar_html);
    formDataToSend.append('short_description_en', short_description_en);
    formDataToSend.append('short_description_fr', short_description_fr);
    formDataToSend.append('short_description_ar', short_description_ar);
    formDataToSend.append('donation_made', donation_made);
    formDataToSend.append('donation_needed', donation_needed);
    formDataToSend.append('is_urgent', is_urgent);
    formDataToSend.append('seo_title_en', seo_title_en);
    formDataToSend.append('seo_title_fr', seo_title_fr);
    formDataToSend.append('seo_title_ar', seo_title_ar);
    formDataToSend.append('seo_description_en', seo_description_en);
    formDataToSend.append('seo_description_fr', seo_description_fr);
    formDataToSend.append('seo_description_ar', seo_description_ar);
    formDataToSend.append('seo_keywords', seo_keywords);
    formDataToSend.append('category_id', category_id);

    if (image) {
        formDataToSend.append('image', image);
    }

    AdvertisementDonService.create(formDataToSend)
        .then(res => {
            getAll();
            resetForm();
            setModal({ add: false });
            setImagePreview("")
            setPreviousImageURL("")

        })
        .catch(error => {
            console.error('Erreur lors de la soumission de l\'annonce :', error);
        })
        .finally(() => {
            setGridLoading(false); 
        });
};

  
  

  // submit function to update a new item
  const onEditSubmit = () => {
    setGridLoading(true);
  
    const {
      title_en,
      title_fr,
      title_ar,
      description_en,
      description_fr,
      description_ar,
      short_description_en,
      short_description_fr,
      short_description_ar,
      donation_made,
      donation_needed,
      is_urgent,
      seo_title_en,
      seo_title_fr,
      seo_title_ar,
      seo_description_en,
      seo_description_fr,
      seo_description_ar,
      seo_keywords,
      category_id,
      image
    } = editFormData;
  
    const description_en_html = draftToHtml(convertToRaw(description_en.getCurrentContent()));
    const description_fr_html = draftToHtml(convertToRaw(description_fr.getCurrentContent()));
    const description_ar_html = draftToHtml(convertToRaw(description_ar.getCurrentContent()));
    const formDataToSend = new FormData();
    formDataToSend.append('title_en', title_en);
    formDataToSend.append('title_fr', title_fr);
    formDataToSend.append('title_ar', title_ar);
    formDataToSend.append('description_en', description_en_html);
    formDataToSend.append('description_fr', description_fr_html);
    formDataToSend.append('description_ar', description_ar_html);
    formDataToSend.append('short_description_en', short_description_en);
    formDataToSend.append('short_description_fr', short_description_fr);
    formDataToSend.append('short_description_ar', short_description_ar);
    formDataToSend.append('donation_made', donation_made);
    formDataToSend.append('donation_needed', donation_needed);
    formDataToSend.append('is_urgent', is_urgent);
    formDataToSend.append('seo_title_en', seo_title_en);
    formDataToSend.append('seo_title_fr', seo_title_fr);
    formDataToSend.append('seo_title_ar', seo_title_ar);
    formDataToSend.append('seo_description_en', seo_description_en);
    formDataToSend.append('seo_description_fr', seo_description_fr);
    formDataToSend.append('seo_description_ar', seo_description_ar);
    formDataToSend.append('seo_keywords', seo_keywords);
    formDataToSend.append('category_id', category_id);
  

    if (image && image instanceof File) {
      formDataToSend.append('image', image);
    }
  
    AdvertisementDonService.update(editId, formDataToSend)
      .then(res => {
        console.log('Annonce mise à jour avec succès !', res);
        getAll();
        setModal({ edit: false });
        resetForm();
        setImagePreview("")
        setPreviousImageURL("")

      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour de l\'annonce :', error);
      })
      .finally(() => {
        setGridLoading(false);
      });
  };
  


  const convertToEditorState = (htmlContent) => {
    if (!htmlContent) return EditorState.createEmpty();
    const blocksFromHTML = convertFromHTML(htmlContent);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
    return EditorState.createWithContent(contentState);
  };
 
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setPreviousImageURL(item.image);
        setEditFormData({
          title_en: item.title_en,
          title_fr: item.title_fr,
          title_ar: item.title_ar,
          description_en: convertToEditorState(item.description_en),
          description_fr: convertToEditorState(item.description_fr),
          description_ar: convertToEditorState(item.description_ar),
          short_description_en: item.short_description_en,
          short_description_fr: item.short_description_fr,
          short_description_ar: item.short_description_ar,
          donation_needed: item.donation_needed,
          donation_made: item.donation_made,
          is_urgent: item.is_urgent,
          seo_title_en: item.seo_title_en,
          seo_title_fr: item.seo_title_fr,
          seo_title_ar: item.seo_title_ar,
          seo_description_en: item.seo_description_en,
          seo_description_fr: item.seo_description_fr,
          seo_description_ar: item.seo_description_ar,
          seo_keywords: item.seo_keywords,
          category_id :item.category_id,
          image:item.image,
        });
        setEditedId(id);
        setModal({ edit: true, add: false });
      }
    });
  };
  

  const onDeleteClick = (id) => {
    setModal({ delete: true }, { add: false },{ edit: false },{details : false });
    setDeletedId(id);
  };

  const [advertisement, setAdvertisement] = useState(null);
  const [loading, setLoading] = useState(false);

const onViewClick = (id) => {
    setModal({ details: true, delete: false, add: false, edit: false });
    setLoading(true);
    AdvertisementDonService.getById(id)
    .then((res) => {
      setAdvertisement(res.data);
    })
    .catch((error) => {
        console.error("Erreur lors de la récupération des détails de l'annonce:", error);
    })
    .finally(() => {
        setLoading(false);
    });
};
  const confirmDelete = () => {
    setGridLoading(true); 
    
    AdvertisementDonService.deleteAdvertisement(deleteId)
      .then(() => {
        getAll();
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de la catégorie :', error);
      })
      .finally(() => {
        setGridLoading(false); 
        closeDeleteModal(); 
      });
  };
  

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    setGridLoading(true); 
    
    const filtered = data.filter(item =>
      item.title_fr.toLowerCase().includes(keyword) ||
      item.short_description_fr.toLowerCase().includes(keyword) ||
      item.seo_title_fr.toLowerCase().includes(keyword) ||
      item.category?.name_fr.toLowerCase().includes(keyword) ||
      moment(item.created_at).format('DD MMM YYYY').toLowerCase().includes(keyword)
    );
    setFilteredData(filtered);
    
    setGridLoading(false); 
  };

  return (
    <React.Fragment>
      <Head title="Annonce List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> Annonce pour les dons</BlockTitle>
              <BlockDes className="text-soft">Vous avez un total de {data.length} annonces pour les dons..</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
             
                  <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="search"
                          // onChange={(e) => onFilterChange(e)}
                          onChange={handleSearch}
                        />
                      </div>
                    </li>

                    <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                      <Button color="primary">
                        <Icon name="plus"></Icon>
                        <span>Ajouter des annonces de dons</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="nk-tb-list is-separate nk-tb-ulist">
            <DataTableHead className="nk-tb-item nk-tb-head">
              <DataTableRow>
                <span className="sub-text">Titre de l'annonce</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Brève description</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Urgent</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text">Besoin de don</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Catégorie</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Créé à</span>
              </DataTableRow>
              
              <DataTableRow className="nk-tb-col-tools text-end">
              <span className="sub-text">Action</span>
              </DataTableRow>
            </DataTableHead>
            {GridLoading ? (
                <DataTableItem className={'text-center height-100'}>
                    <DataTableRow className={'spinner-container'}>
                      <Spinner color="primary" />
                    </DataTableRow>
                  </DataTableItem>
            ) : 
            currentItems.length > 0
              ? currentItems.map((item) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow size="sm">
                        <span className="tb-product">
                          <img src={item.image ? item.image : ProductH} alt="product" className="thumb" />
                          <span className="title">{item.title_fr}</span>
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{item?.short_description_fr || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="md1">
                        <span>
                          {item?.is_urgent === 1 ? (
                            <Badge color="success">Oui</Badge>
                          ) : (
                            <Badge color="danger">Non</Badge>
                          )}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                         <span>{item?.donation_needed || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                         <span>{item?.category?.name_fr || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="mb">
                        <span>{item?.created_at ? moment(item.created_at).format('DD MMM YYYY') : '--'}</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#view"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      onViewClick(item.id);
                                    }}
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>Voir la publicité </span>
                                  </DropdownItem>
                                </li>
                                  <li onClick={() => onEditClick(item.id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Modifier</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => onDeleteClick(item.id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#delete"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                     <Icon name="trash"></Icon>
                                      <span>Supprimer</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              : null}
          </div>
          <PreviewAltCard>
          {filteredData.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">Aucune annonce de dons trouvée</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>


        <FormModal modal={modal.add} modalType="add" formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit} categoryOptions={categoryOptions} setImagePreview={setImagePreview} imagePreview={imagePreview} previousImageURL={previousImageURL} GridLoading={GridLoading} />
        <FormModal modal={modal.edit} modalType="edit" formData={editFormData} setFormData={setEditFormData} closeModal={closeEditModal} onSubmit={onEditSubmit} categoryOptions={categoryOptions} setImagePreview={setImagePreview} imagePreview={imagePreview} previousImageURL={previousImageURL} GridLoading={GridLoading}/>
        <DeleteModal modal={modal.delete}  closeModal={closeDeleteModal} onSubmit={confirmDelete} GridLoading={GridLoading} />
        <AdvertisementsDonDetails modal={modal.details}  closeModal={closeDeleteModal} advertisement={advertisement}  loading={loading}/>
      </Content>
      
    </React.Fragment>

    
  );
};

export default AdvertisementsDonList;