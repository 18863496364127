import React, { useEffect, useState } from "react";
import {
  Icon,
  Col
} from "../../../components/Component";
import {
  Badge,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { SliderService } from "../../../_services/slider.service";
import { Link } from "react-router-dom";

const SliderDetails = ({modal, closeModal,viewId}) => {
  const [slider, setSlider] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    SliderService.getById(viewId)
      .then((res) => {
        setSlider(res.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des détails de slider:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [viewId]);

  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          slider && (
            <>
           
           
           <Row>
              <Col md="12">
                <h3>Details de slider</h3>
              </Col>
              <br />
              <br />
              <br />
              <Col md="12" className="text-center">
                <img src={slider.image} alt="" style={{ maxWidth: "50%", height: "auto" }} />
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Titre (en)</span>
                <span className="caption-text">{slider.title_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre (fr)</span>
                <span className="caption-text">{slider.title_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre (ar)</span>
                <span className="caption-text">{slider.title_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Sous-titre (en)</span>
                <span className="caption-text">{slider.sub_title_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Sous-titre (fr)</span>
                <span className="caption-text">{slider.sub_title_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Sous-titre (ar)</span>
                <span className="caption-text">{slider.sub_title_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Priority</span>
                <span className="caption-text">{slider.priority}</span>
              </Col>
              {slider.btn_active ? (
                <Col md="6">
                  <span className="sub-text">Button Text</span>
                  <span className={`caption-text text-green`}>{slider.btn_text}</span>
                </Col>
              ) : null}
             {slider.btn_active ? (
              <Col md="6">
                  <span className="sub-text">Button Link</span>
                  <Link className={`caption-text ${slider.btn_active === 0 ? 'text-red' : 'text-green'}`} to={slider.btn_link}>
                      {slider.btn_link}
                  </Link>
              </Col>
              ) : null}
            <Col md="6">
              <span className="sub-text">Button Active</span>
              <Badge color={slider.btn_active === 0 ? 'danger' : 'success'}>
                {slider.btn_active === 0 ? 'Non' : 'Oui'}
              </Badge>
            </Col>
            </Row>

            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default SliderDetails;
