import React, { useState, useEffect  } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import ProductH from "../../../images/product/h.png";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Spinner,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  PaginationComponent,
  PreviewAltCard,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import FormModal from "./FormModal";
import DeleteModal from "./DeleteModal";
import moment from 'moment';
import ProjectDetails from "./ProjectDetails";

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { convertFromHTML, ContentState } from 'draft-js';
import { PartnerService } from "../../../_services/partner.service";
import { ProjectService } from "../../../_services/project.service";
import FormModalGallery from "./FormModalGallery";

export const ProjectList = () => {
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    delete :false,
    details: false,
    addImages:false,
    addFiles:false
  });

  const [imagePreview, setImagePreview] = useState('');
 
  
  const [partner, setPartner] = useState([]);

  const getPartner = () => {
    setGridLoading(true)
    PartnerService.getAll()
      .then(res => {
        setPartner(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setGridLoading(false)
      })
  }
  useEffect(() => {
    getPartner()
  }, [])

  const partnerOptions = partner.map(part => ({
    value: part.id,
    label: part.name_fr
  }));



  const [editId, setEditedId] = useState();
  const [deleteId, setDeletedId] = useState();
  const [viewId, setViewId] = useState();
  const [galleryId, setGalleryId] = useState();
  const [fileId, setFileId] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [GridLoading, setGridLoading] = useState(false)

  const getAll = () => {
    setGridLoading(true)
    ProjectService.getAll()
      .then(res => {
        setData(res.data)
        setFilteredData(res.data);
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setGridLoading(false)
      })
  }
  useEffect(() => {
    getAll()
  }, [])
const [formData, setFormData] = useState({
  name_en: "",
  name_fr: "",
  name_ar: "",
  description_en: EditorState.createEmpty(),
  description_fr: EditorState.createEmpty(),
  description_ar: EditorState.createEmpty(),
  image: null,
  short_description_en: "",
  short_description_fr: "",
  short_description_ar: "",
  report: "",
  seo_title_en: "",
  seo_title_fr: "",
  seo_title_ar: "",
  seo_description_en: "",
  seo_description_fr: "",
  seo_description_ar: "",
  seo_keywords: "",
  partner_ids:[],
  btn_text_en:"",
  btn_text_fr:"",
  btn_text_ar:"",
  btn_link:"",
  btn_active:0
});

const [editFormData, setEditFormData] = useState({
  name_en: "",
  name_fr: "",
  name_ar: "",
  description_en: EditorState.createEmpty(),
  description_fr: EditorState.createEmpty(),
  description_ar: EditorState.createEmpty(),
  image: null,
  short_description_en: "",
  short_description_fr: "",
  short_description_ar: "",
  report: "",
  seo_title_en: "",
  seo_title_fr: "",
  seo_title_ar: "",
  seo_description_en: "",
  seo_description_fr: "",
  seo_description_ar: "",
  seo_keywords: "",
  partner_ids:[],
  btn_text_en:"",
  btn_text_fr:"",
  btn_text_ar:"",
  btn_link:"",
  btn_active:0
});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name_en: "",
      name_fr: "",
      name_ar: "",
      description_en: EditorState.createEmpty(),
      description_fr: EditorState.createEmpty(),
      description_ar: EditorState.createEmpty(),
      image: null,
      short_description_en: "",
      short_description_fr: "",
      short_description_ar: "",
      report: "",
      seo_title_en: "",
      seo_title_fr: "",
      seo_title_ar: "",
      seo_description_en: "",
      seo_description_fr: "",
      seo_description_ar: "",
      seo_keywords: "",
      partner_ids:[],
      btn_text_en:"",
      btn_text_fr:"",
      btn_text_ar:"",
      btn_link:"",
      btn_active:0

    });
  };
  const [previousImageURL, setPreviousImageURL] = useState('');

  const closeModal = () => {
    setModal({ add: false })
    resetForm();
    setPreviousImageURL("")
    setImagePreview("")

  };

  const closeEditModal = () => {
    setModal({ edit: false })
    resetForm();
    setImagePreview("")
    setPreviousImageURL("")
  };
  const closeDeleteModal = () => {
    setModal({ delete: false })
    resetForm();
    setImagePreview("")
  };


  // submit function to add a new item
  const onFormSubmit = () => {    
    setGridLoading(true); 

    const { name_en, name_fr, name_ar, description_en, description_fr, description_ar, short_description_en, short_description_fr, short_description_ar,report, seo_title_en, seo_title_fr, seo_title_ar, seo_description_en, seo_description_fr, seo_description_ar, seo_keywords, image,partner_ids ,btn_text_en,btn_text_fr,btn_text_ar,btn_link,btn_active  } = formData;

    const description_en_html = draftToHtml(convertToRaw(description_en.getCurrentContent()));
    const description_fr_html = draftToHtml(convertToRaw(description_fr.getCurrentContent()));
    const description_ar_html = draftToHtml(convertToRaw(description_ar.getCurrentContent()));

    const formDataToSend = new FormData();
    formDataToSend.append('name_en', name_en);
    formDataToSend.append('name_fr', name_fr);
    formDataToSend.append('name_ar', name_ar);
    formDataToSend.append('description_en', description_en_html);
    formDataToSend.append('description_fr', description_fr_html);
    formDataToSend.append('description_ar', description_ar_html);
    formDataToSend.append('short_description_en', short_description_en);
    formDataToSend.append('short_description_fr', short_description_fr);
    formDataToSend.append('short_description_ar', short_description_ar);
    formDataToSend.append('report', report);
    formDataToSend.append('seo_title_en', seo_title_en);
    formDataToSend.append('seo_title_fr', seo_title_fr);
    formDataToSend.append('seo_title_ar', seo_title_ar);
    formDataToSend.append('seo_description_en', seo_description_en);
    formDataToSend.append('seo_description_fr', seo_description_fr);
    formDataToSend.append('seo_description_ar', seo_description_ar);
    formDataToSend.append('seo_keywords', seo_keywords);
    formDataToSend.append('btn_text_en', btn_text_en);
    formDataToSend.append('btn_text_fr', btn_text_fr);
    formDataToSend.append('btn_text_ar', btn_text_ar);
    formDataToSend.append('btn_link', btn_link);
    formDataToSend.append('btn_active', btn_active);


    partner_ids.forEach(partnerId => {
      formDataToSend.append('partner_ids[]', partnerId);
    });
    if (image) {
        formDataToSend.append('image', image);
    }

    ProjectService.create(formDataToSend)
        .then(res => {
            getAll();
            resetForm();
            setModal({ add: false });
            setImagePreview("")
            setPreviousImageURL("")

        })
        .catch(error => {
            console.error('Erreur lors de la soumission de Project :', error);
        })
        .finally(() => {
            setGridLoading(false); 
        });
  };

  
  

  // submit function to update a new item
  const onEditSubmit = () => {
    setGridLoading(true);
  
    const {
      name_en,
      name_fr,
      name_ar,
      description_en,
      description_fr,
      description_ar,
      short_description_en,
      short_description_fr,
      short_description_ar,
      report,
      seo_title_en,
      seo_title_fr,
      seo_title_ar,
      seo_description_en,
      seo_description_fr,
      seo_description_ar,
      seo_keywords,
      image,
      partner_ids,
      btn_text_en,
      btn_text_fr,
      btn_text_ar,
      btn_link,
      btn_active 
    } = editFormData;
  
    const description_en_html = draftToHtml(convertToRaw(description_en.getCurrentContent()));
    const description_fr_html = draftToHtml(convertToRaw(description_fr.getCurrentContent()));
    const description_ar_html = draftToHtml(convertToRaw(description_ar.getCurrentContent()));
  
    const formDataToSend = new FormData();
    formDataToSend.append('name_en', name_en);
    formDataToSend.append('name_fr', name_fr);
    formDataToSend.append('name_ar', name_ar);
    formDataToSend.append('description_en', description_en_html);
    formDataToSend.append('description_fr', description_fr_html);
    formDataToSend.append('description_ar', description_ar_html);
    formDataToSend.append('short_description_en', short_description_en);
    formDataToSend.append('short_description_fr', short_description_fr);
    formDataToSend.append('short_description_ar', short_description_ar);
    formDataToSend.append('report', report);
    formDataToSend.append('seo_title_en', seo_title_en);
    formDataToSend.append('seo_title_fr', seo_title_fr);
    formDataToSend.append('seo_title_ar', seo_title_ar);
    formDataToSend.append('seo_description_en', seo_description_en);
    formDataToSend.append('seo_description_fr', seo_description_fr);
    formDataToSend.append('seo_description_ar', seo_description_ar);
    formDataToSend.append('seo_keywords', seo_keywords);
    formDataToSend.append('btn_active', btn_active);
  
    if (btn_active) {
      formDataToSend.append('btn_text_en', btn_text_en);
      formDataToSend.append('btn_text_fr', btn_text_fr);
      formDataToSend.append('btn_text_ar', btn_text_ar);
      formDataToSend.append('btn_link', btn_link);
    } else {
      formDataToSend.append('btn_text_en', '');
      formDataToSend.append('btn_text_fr', '');
      formDataToSend.append('btn_text_ar', '');
      formDataToSend.append('btn_link', '');
    }
    partner_ids.forEach(partnerId => {
      formDataToSend.append('partner_ids[]', partnerId);
    });

    if (image && image instanceof File) {
      formDataToSend.append('image', image);
    }
  
    ProjectService.update(editId, formDataToSend)
      .then(res => {
        console.log('Program mise à jour avec succès !', res);
        getAll();
        setModal({ edit: false });
        resetForm();
        setImagePreview("")
        setPreviousImageURL("")

      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour de Program :', error);
      })
      .finally(() => {
        setGridLoading(false);
      });
  };
  


  const convertToEditorState = (htmlContent) => {
    if (!htmlContent) return EditorState.createEmpty();
    const blocksFromHTML = convertFromHTML(htmlContent);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
    return EditorState.createWithContent(contentState);
  };
  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        console.log('item',item)
        const partnerIds = item?.partners.map(partner => partner.id);
        setPreviousImageURL(item.image);
        setEditFormData({
          name_en: item.name_en,
          name_fr: item.name_fr,
          name_ar: item.name_ar,
          description_en: convertToEditorState(item.description_en),
          description_fr: convertToEditorState(item.description_fr),
          description_ar: convertToEditorState(item.description_ar),
          short_description_en: item.short_description_en,
          short_description_fr: item.short_description_fr,
          short_description_ar: item.short_description_ar,
          report: item.report,
          seo_title_en: item.seo_title_en,
          seo_title_fr: item.seo_title_fr,
          seo_title_ar: item.seo_title_ar,
          seo_description_en: item.seo_description_en,
          seo_description_fr: item.seo_description_fr,
          seo_description_ar: item.seo_description_ar,
          seo_keywords: item.seo_keywords,
          image:item.image,
          partner_ids: partnerIds, 
          btn_text_en:item.btn_text_en,
          btn_text_fr:item.btn_text_fr,
          btn_text_ar:item.btn_text_ar,
          btn_link:item.btn_link,
          btn_active:item.btn_active,
        });
        setEditedId(id);
        setModal({ edit: true, add: false });
      }
    });
  };
  

  const onDeleteClick = (id) => {
    setModal({ delete: true }, { add: false },{ edit: false },{details : false });
    setDeletedId(id);
  };

  const [project, setProjectDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const onViewClick = (id) => {
    setModal({ details: true, delete: false, add: false, edit: false });
    setViewId(id);
    setLoading(true);
    ProjectService.getById(id)
    .then((res) => {
        setProjectDetails(res.data);
    })
    .catch((error) => {
        console.error("Erreur lors de la récupération des détails du projet:", error);
    })
    .finally(() => {
        setLoading(false);
    });
};
  const confirmDelete = () => {
    setGridLoading(true); 
    
    ProjectService.deleteProject(deleteId)
      .then(() => {
        getAll(); 
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de la Projects :', error);
      })
      .finally(() => {
        setGridLoading(false); 
        closeDeleteModal(); 
      });
  };
  

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle search
  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    setGridLoading(true); 
    
    const filtered = data.filter(item =>
      item.name_fr.toLowerCase().includes(keyword) ||
      item.short_description_fr.toLowerCase().includes(keyword) ||
      item.seo_title_fr.toLowerCase().includes(keyword) ||
      item?.report.toLowerCase().includes(keyword) ||
      moment(item.created_at).format('DD MMM YYYY').toLowerCase().includes(keyword)
    );
    setFilteredData(filtered);
    
    setGridLoading(false); 
  };
  const [gallery, setGallery] = useState({
    image:[]
  });

  const closeGalleryModal = () => {
    setModal({ addImages: false })
    resetForm();
    setGallery("")
  };
  const closeFileModal = () => {
    setModal({ addFiles: false })
    resetForm();
    setFile("")
  };
  const [projectgallery, setProjectGallery] = useState(null);

  const onAddImageClick = (projectId) => {
    setGalleryId(projectId);
    setModal({ addImages: true ,details: false, delete: false, add: false, edit: false });
    setLoading(true);
    ProjectService.getGallery(projectId)
    .then((res) => {
      setProjectGallery(res.data);
    })
    .catch((error) => {
        console.error("Erreur lors de la récupération des images du projet:", error);
    })
    .finally(() => {
        setLoading(false);
    });
  };

  const [projectReports, setProjectReports] = useState(null);

  const onAddFileClick = (projectId) => {
    setFileId(projectId);
    setModal({ addFiles:true ,addImages: false ,details: false, delete: false, add: false, edit: false });
    setLoading(true);
    ProjectService.getReports(projectId)
    .then((res) => {
      setProjectReports(res.data);
    })
    .catch((error) => {
        console.error("Erreur lors de la récupération des Reports du projet:", error);
    })
    .finally(() => {
        setLoading(false);
    });
  };
  const [file, setFile] = useState({
    files :[],
  });
  const storeFile = () => {
    setGridLoading(true);

    const { files } = file;

    if (files && files.length > 0) { 
        const formDataToSend = new FormData();
        formDataToSend.append('project_id', fileId);

        files.forEach((fileData, index) => {
            formDataToSend.append(`files[]`, fileData.file);
            formDataToSend.append(`file_names[]`, fileData.name);
        });

        ProjectService.storeReport(fileId, formDataToSend)
            .then(res => {
                getAll();
                resetForm();
                setModal({ addFiles: false });
                setFile({ files: [] }); 
            })
            .catch(error => {
                console.error('Error during file submission to Project:', error);
            })
            .finally(() => {
                setGridLoading(false);
            });
    } else {
        console.error('No files to submit.');
        setGridLoading(false);
    }
};








  const storeGallery = () => {    
    setGridLoading(true); 

    const { image } = gallery;

    const formDataToSend = new FormData();

    formDataToSend.append('project_id', galleryId);

    if (image && image.length > 0) { 
        image.forEach((imageData, index) => {
            formDataToSend.append(`image[${index}]`, imageData.file); 
        });

        ProjectService.storeGallery(galleryId, formDataToSend)
            .then(res => {
                getAll();
                resetForm();
                setModal({ addImages: false });
                setGallery(""); 
            })
            .catch(error => {
                console.error('Error during image submission to Project:', error);
            })
            .finally(() => {
                setGridLoading(false); 
            });
    } else {
        console.error('No images to submit.');
        setGridLoading(false); 
    }
  };

  const deleteImage = (projectId, galleryId) => {
    setGridLoading(true); 
    console.log('projectid',projectId)
    console.log('galleryId',galleryId)
    ProjectService.deleteGalleryImage(projectId, galleryId)
      .then((res) => {
        getAll(); 
        setProjectGallery(res.gallery)
      })
      .catch((error) => {
        console.error('Error deleting gallery image:', error);
      })
      .finally(() => {
        setGridLoading(false); 
      });
  };

  const deleteReport = (projectId, reportId) => {
    setGridLoading(true); 
    console.log('projectid',projectId)
    console.log('reportId',reportId)
    ProjectService.deleteReport(projectId, reportId)
      .then((res) => {
        getAll(); 
        setProjectReports(res.data)
      })
      .catch((error) => {
        console.error('Error deleting gallery image:', error);
      })
      .finally(() => {
        setGridLoading(false); 
      });
  };


  return (
    <React.Fragment>
      <Head title="Programs List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> Program</BlockTitle>
              <BlockDes className="text-soft">Vous avez au total {data.length} Programs.</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                  <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="search"
                          // onChange={(e) => onFilterChange(e)}
                          onChange={handleSearch}
                        />
                      </div>
                    </li>
                    <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                      <Button color="primary">
                        <Icon name="plus"></Icon>
                        <span>Ajouter Program</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="nk-tb-list is-separate nk-tb-ulist">
            <DataTableHead className="nk-tb-item nk-tb-head">
            <DataTableRow size="md">
                <span className="sub-text">Image</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text">Nom de program</span>
              </DataTableRow>

           
              <DataTableRow size="lg">
                <span className="sub-text">Titre de référencement</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Créé à</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Gallery & Attachements</span>
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools text-end">
              <span className="sub-text">Action</span>
              </DataTableRow>
            </DataTableHead>
            {GridLoading ? (
              <DataTableItem className={'text-center height-100'}>
                  <DataTableRow className={'spinner-container'}>
                    <Spinner color="primary" />
                  </DataTableRow>
                </DataTableItem>
            ) : 
            currentItems.length > 0
              ? currentItems.map((item) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow size="sm">
                        <span className="tb-product">
                          <img src={item.image ? item.image : ProductH} alt="product" className="thumb" />
                        </span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                         <span>{item?.name_fr || '--'}</span>
                      </DataTableRow>

                      <DataTableRow size="lg">
                         <span>{item?.seo_title_fr || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="mb">
                        <span>{item?.created_at ? moment(item.created_at).format('DD MMM YYYY') : '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                          <Button variant="icon" onClick={() => onAddImageClick(item.id)}>
                              <Icon name="img-fill" />
                              Gallery
                          </Button>
                          <br />
                          <Button variant="icon" onClick={() => onAddFileClick(item.id)}>
                              {/* <Icon name="link-v" />   */}
                              <Icon name="file-pdf" />
                              Attachements
                          </Button>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#view"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      onViewClick(item.id);
                                    }}
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>Voir Program </span>
                                  </DropdownItem>
                                </li>
                                  <li onClick={() => onEditClick(item.id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Modifier</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => onDeleteClick(item.id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#delete"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                     <Icon name="trash"></Icon>
                                      <span>Supprimer</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              : null}
          </div>
          <PreviewAltCard>
            {filteredData.length ||data.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">Aucune program trouvée</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>


        <FormModal modal={modal.add} modalType="add" formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit} partnerOptions={partnerOptions} setImagePreview={setImagePreview} imagePreview={imagePreview} previousImageURL={previousImageURL} GridLoading={GridLoading} />
        <FormModal modal={modal.edit} modalType="edit" formData={editFormData} setFormData={setEditFormData} closeModal={closeEditModal} onSubmit={onEditSubmit} partnerOptions={partnerOptions} setImagePreview={setImagePreview} imagePreview={imagePreview} previousImageURL={previousImageURL} GridLoading={GridLoading} />
        <DeleteModal modal={modal.delete}  closeModal={closeDeleteModal} onSubmit={confirmDelete} GridLoading={GridLoading} />
        <ProjectDetails modal={modal.details}  closeModal={closeDeleteModal} viewId={viewId} project={project} loading={loading} />
        <FormModalGallery 
          modal={modal.addImages}  
          closeModal={closeGalleryModal} 
          projectgallery={projectgallery} 
          onSubmit={storeGallery}  
          gallery={gallery} 
          setGallery={setGallery} 
          deleteImage={deleteImage} 
          modalType="gallery"
        />
        <FormModalGallery 
          modal={modal.addFiles}  
          closeModal={closeFileModal} 
          projectgallery={projectReports} 
          onSubmit={storeFile}  
          gallery={file} 
          setGallery={setFile} 
          modalType="file"
          deleteImage={deleteReport} 
        />
      </Content>
      
    </React.Fragment>

    
  );
};

export default ProjectList;