import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Spinner,
  Col,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Block, BlockHead, BlockTitle } from "../../../components/Component";
import { GeneralSettingsService } from "../../../_services/generalSettings.service";
import Skeleton from "react-loading-skeleton";
import classnames from "classnames";
import ReactCountryFlag from "react-country-flag";
export const GeneralSettingsList = () => {
  const [data, setData] = useState([]);
  const [editFormData, setEditFormData] = useState({});
  const [gridLoading, setGridLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const getAll = () => {
    setGridLoading(true);
    GeneralSettingsService.getAll()
      .then(res => {
        setData(res.data);
       
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setGridLoading(false);
      });
  };

  useEffect(() => {
    getAll();
  }, []);
useEffect(() => {
    const firstItem = data; 
    setEditFormData({
      id: firstItem.id,
      phone_number_1: firstItem.phone_number_1,
      phone_number_2: firstItem.phone_number_2,
      email_1: firstItem.email_1,
      email_2: firstItem.email_2,
      main_address: firstItem.main_address,
      google_map: firstItem.google_map,
      short_description_en: firstItem.short_description_en,
      short_description_fr: firstItem.short_description_fr,
      short_description_ar: firstItem.short_description_ar,
      linkedin_url: firstItem.linkedin_url,
      facebook_url: firstItem.facebook_url,
      x_url: firstItem.x_url,
    });
}, [data]);

const [errors, setErrors] = useState({});


const handleChange = prop => event => {
  const value = event.target.value;
  let error = "";

  // Validation de l'e-mail
  if (prop === "email_1" || prop === "email_2") {
    if (!/\S+@\S+\.\S+/.test(value)) {
      error = "Adresse e-mail invalide";
    }
  }
  // Validation du numéro de téléphone
  if (prop === "phone_number_1" || prop === "phone_number_2") {
    if (!/^\+?\(\d{3}\)\d{8}$/.test(value)) {
      error = "Numéro de téléphone invalide";
    }
  }

  setErrors(prevErrors => ({ ...prevErrors, [prop]: error }));
  setEditFormData({ ...editFormData, [prop]: value });
};


const onEditSubmit = event => {
  event.preventDefault();

  let formIsValid = true;
  let newErrors = {};

  // Validation de l'e-mail
  if (!/\S+@\S+\.\S+/.test(editFormData.email_1)) {
    newErrors = { ...newErrors, email_1: "Adresse e-mail 1 invalide" };
    formIsValid = false;
  }


  if (formIsValid) {
    setGridLoading(true);
    GeneralSettingsService.update(editFormData?.id, editFormData)
      .then(res => {
        console.log('Paramètres généraux mis à jour avec succès !', res);
      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour des paramètres généraux :', error);
      })
      .finally(() => {
        setGridLoading(false);
      });
  } else {
    setErrors(newErrors);
  }
};


  return (
    <React.Fragment>
      <Head title="Liste des paramètres généraux"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockTitle page>Paramètres généraux</BlockTitle>
        </BlockHead>
        <Block>
          <div className="nk-tb-list is-separate nk-tb-ulist">
            {gridLoading  ? (
              <div className="spinner-container">
                <Spinner color="primary" />
              </div>
            ) : (
              <Form className="row gy-4" onSubmit={onEditSubmit}>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Numéro de téléphone 1</label>
                    <input
                        type="text"
                        value={editFormData.phone_number_1 || ''}
                        onChange={handleChange('phone_number_1')}
                        className="form-control"
                      />
                  </div>
                  {errors.phone_number_1 && <span className="text-danger">{errors.phone_number_1}</span>}
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Numéro de téléphone 2</label>
                    <input
                      type="text"
                      value={editFormData.phone_number_2 || ''}
                      onChange={handleChange('phone_number_2')}
                      className="form-control"
                    />
                  </div>
                  {errors.phone_number_2 && <span className="text-danger">{errors.phone_number_2}</span>}
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">Adresse e-mail 1</label>
                    <input
                        type="email"
                        value={editFormData.email_1 || ''}
                        onChange={handleChange('email_1')}
                        className="form-control"
                      />
                  </div>
                  {errors.email_1 && <span className="text-danger">{errors.email_1}</span>}
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">Adresse e-mail 2</label>
                    <input
                        type="email"
                        value={editFormData.email_2 || ''}
                        onChange={handleChange('email_2')}
                        className="form-control"
                      />
                      {errors.email_2 && <span className="text-danger">{errors.email_2}</span>}
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">Adresse principale</label>
                    <input
                      type="text"
                      value={editFormData.main_address || ''}
                      onChange={handleChange('main_address')}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">Carte Google</label>
                    <input
                      type="text"
                      value={editFormData.google_map || ''}
                      onChange={handleChange('google_map')}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Nav tabs className="justify-content-center">
                      <NavItem>
                          <NavLink
                              className={classnames({ active: activeTab === "1" })}
                              onClick={() => toggle("1")}
                              style={{ cursor: 'pointer' }} 
                          >
                              <ReactCountryFlag countryCode="US" svg style={{ marginRight: '5px' }}/> English
                          </NavLink>
                      </NavItem>
                      <NavItem>
                          <NavLink
                              className={classnames({ active: activeTab === "2" })}
                              onClick={() => toggle("2")}
                              style={{ cursor: 'pointer' }} 
                          >
                              <ReactCountryFlag countryCode="FR" svg style={{ marginRight: '5px' }}/> Francais
                          </NavLink>
                      </NavItem>
                      <NavItem>
                          <NavLink
                              className={classnames({ active: activeTab === "3" })}
                              onClick={() => toggle("3")}
                              style={{ cursor: 'pointer' }}
                          >
                              <ReactCountryFlag countryCode="TN" svg style={{ marginRight: '5px' }}/> Arabic
                          </NavLink>
                      </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Col md="12">
                          <div className="form-group">
                            <label className="form-label">Description courte (En)</label>
                            <textarea
                              value={editFormData.short_description_en || ''}
                              onChange={handleChange('short_description_en')}
                              className="form-control"
                            ></textarea>
                          </div>
                        </Col>
                    </TabPane>
                    <TabPane tabId="2">
                      <Col md="12">
                          <div className="form-group">
                            <label className="form-label">Description courte (Fr)</label>
                            <textarea
                              value={editFormData.short_description_fr || ''}
                              onChange={handleChange('short_description_fr')}
                              className="form-control"
                            ></textarea>
                          </div>
                        </Col>
                    </TabPane>
                    <TabPane tabId="3">
                      <Col md="12">
                          <div className="form-group">
                            <label className="form-label">Description courte (Ar)</label>
                            <textarea
                              value={editFormData.short_description_ar || ''}
                              onChange={handleChange('short_description_ar')}
                              className="form-control"
                            ></textarea>
                          </div>
                        </Col>
                    </TabPane>
                  </TabContent>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">URL LinkedIn</label>
                    <input
                      type="text"
                      value={editFormData.linkedin_url || ''}
                      onChange={handleChange('linkedin_url')}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">URL Facebook</label>
                    <input
                      type="text"
                      value={editFormData.facebook_url || ''}
                      onChange={handleChange('facebook_url')}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">URL X</label>
                    <input
                      type="text"
                      value={editFormData.x_url || ''}
                      onChange={handleChange('x_url')}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="12">
                  <Button color="primary" size="md" type="submit" disabled={gridLoading}>
                    {gridLoading ? <Spinner size="sm" color="light" /> : "Modifier les paramètres"}
                  </Button>
                </Col>
              </Form>
            )}
          </div>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default GeneralSettingsList;
