import React, { useCallback, useEffect, useState } from "react";
import {
  Icon,
  Button,
  Col,
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Form,
  Spinner
} from "reactstrap";
import { useForm } from "react-hook-form";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDropzone } from 'react-dropzone';

const FormModal = ({modal, closeModal, onSubmit, formData, setFormData, modalType,setImagePreview,imagePreview,previousImageURL,GridLoading,ErreurPriority}) => {
  useEffect(() => {
    reset(formData)
  }, [formData]);

  const { reset, register, handleSubmit, formState: { errors } } = useForm();


  const handleChange = prop => event => {
    setFormData({ ...formData, [prop]: event.target.value })
  }
  useEffect(() => {
    reset(formData);
    if (formData.image instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(formData.image);
    } else if (typeof formData.image === 'string') {
        setImagePreview("");
    }
}, [formData]);
const [btnActive, setBtnActive] = useState(formData.btn_active || false);

    useEffect(() => {
      reset(formData);
      setBtnActive(formData.btn_active || false);
    }, [formData]);

    const handleSwitchChange = () => {
      const newValue = btnActive ? 0 : 1; 
      setBtnActive(!btnActive);
      setFormData({ ...formData, btn_active: newValue });
    };

  const onDrop = useCallback((acceptedFiles) => {
    setFormData({ ...formData, image: acceptedFiles[0] });
  }, [formData, setFormData]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  
  return (
<Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
  <ModalBody>
    <a
      href="#cancel"
      onClick={(ev) => {
        ev.preventDefault();
        closeModal();
      }}
      className="close"
    >
      <Icon name="cross-sm"></Icon>
    </a>
    {GridLoading ? (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    ) : (
    <div className="p-2">
      <h5 className="title">{modalType === "add" ? "Ajouter un Slider" : "Modifier le Slider"}</h5>
      <div className="mt-4">
        <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
        {modalType === "edit" &&
        <Col md="12">
          { formData.image ? 
            <img src={previousImageURL} alt="Current" className="img-preview" /> : null }
        </Col>
        }
        <Col md="12">
            <div className="form-group">
            <label className="form-label">{modalType === "edit" ? 'Si vous voulez changer l\'image' : 'Image'}</label>
                <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    {imagePreview ? (
                        <img src={imagePreview} alt="Preview" className="img-preview" />
                    ) : (
                        <p>Glissez et déposez une image ici, ou cliquez pour sélectionner une image</p>
                    )}
                </div>
            </div>
        </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Titre (en)</label>
              <input
                type="text"
                {...register('title_en', { required: "Ce champ est requis" })}
                value={formData.title_en}
                placeholder="Entrez le titre (en)"
                onChange={handleChange('title_en')}
                className="form-control" />
              {errors.title_en && <span className="invalid">{errors.title_en.message}</span>}
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Titre (fr)</label>
              <input
                type="text"
                {...register('title_fr', { required: "Ce champ est requis" })}
                value={formData.title_fr}
                placeholder="Entrez le titre (fr)"
                onChange={handleChange('title_fr')}
                className="form-control" />
              {errors.title_fr && <span className="invalid">{errors.title_fr.message}</span>}
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Titre (ar)</label>
              <input
                type="text"
                {...register('title_ar', { required: "Ce champ est requis" })}
                value={formData.title_ar}
                placeholder="Entrez le titre (ar)"
                onChange={handleChange('title_ar')}
                className="form-control" />
              {errors.title_ar && <span className="invalid">{errors.title_ar.message}</span>}
            </div>
          </Col>
          <hr />
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Sous-titre (en)</label>
              <input
                type="text"
                {...register('sub_title_en')}
                value={formData.sub_title_en}
                placeholder="Entrez Sous-titre (en)"
                onChange={handleChange('sub_title_en')}
                className="form-control" />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Sous-titre (fr)</label>
              <input
                type="text"
                {...register('sub_title_fr')}
                value={formData.sub_title_fr}
                placeholder="Entrez Sous-titre (fr)"
                onChange={handleChange('sub_title_fr')}
                className="form-control" />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Sous-titre (ar)</label>
              <input
                type="text"
                {...register('sub_title_ar')}
                value={formData.sub_title_ar}
                placeholder="Entrez Sous-titre (ar)"
                onChange={handleChange('sub_title_ar')}
                className="form-control" />
            </div>
          </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label">Priority</label>
            <input
              type="number"
              {...register('priority')}
              value={formData.priority}
              placeholder="Entrez la priorité"
              onChange={handleChange('priority')}
              className={`form-control`} 
            />
          </div>
        </Col>
        <hr />
        <Col md="6">
          <div className="form-group">
            <label className="form-label">Activer le deuxième bouton</label>
            <div className="d-flex align-items-center"> 
              <div className="mr-2"> 
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitch"
                    checked={btnActive}
                    onChange={handleSwitchChange}
                  />
                  <label className="custom-control-label" htmlFor="customSwitch">
                    {btnActive ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label">{btnActive ? 'Titre du deuxième bouton' : ""}</label>
            <input
              type="text"
              {...register('btn_text')}
              value={formData.btn_text}
              placeholder="Entrez le Titre du deuxième bouton"
              onChange={handleChange('btn_text')}
              className="form-control"
              style={{ display: btnActive ? 'block' : 'none' }} 
            />
            {ErreurPriority && (
              <div className="invalid-feedback">{ErreurPriority}</div>
            )}
          </div>
        </Col>
          <hr />
          <Col md="12">
            <div className="form-group" style={{ display: btnActive ? 'block' : 'none' }}> 
              <label className="form-label">Lien pour le deuxième bouton</label>
              <input
                type="text"
                {...register('btn_link')}
                value={formData.btn_link}
                placeholder="Entrez le Lien du deuxième bouton"
                onChange={handleChange('btn_link')}
                className={`form-control ${ErreurPriority ? 'is-invalid' : ''}`} 
                />
                {ErreurPriority === "The btn link must be a valid URL." && (
                <div className="invalid-feedback">{ErreurPriority}</div>
              )}
            </div>
          </Col>
          <hr />

          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
              <li>
              <Button color="primary" size="md" type="submit" disabled={GridLoading}>
                  {GridLoading ?
                      <Spinner size="sm" color="light" />
                      :
                      (modalType === "add" ? "Ajouter un slider" : "Modifier le slider")
                  }
              </Button>
              </li>
              <li>
                <Button
                  onClick={(ev) => {
                    ev.preventDefault();
                    closeModal();
                  }}
                  className="link link-light"
                >
                  Annuler
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </div>
    </div>
    )}
  </ModalBody>
</Modal>

  );
};

export default FormModal;
