import React from "react";
import {
  Icon,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Row,
  Badge
} from "reactstrap";
import { Link } from "react-router-dom";

const GroupSliderDetails = ({modal, closeModal,groupeSlider,loading}) => {

  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          groupeSlider && (
            <>
           
           
           <Row>
              <Col md="12">
                <h3>Details de Collaborateur</h3>
              </Col>
              <br />
              <br />
              <br />
              <Col md="12" className="text-center">
                <img src={groupeSlider?.image} alt="" style={{ maxWidth: "50%", height: "auto" }} />
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Titre (en)</span>
                <span className="caption-text">{groupeSlider?.title_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre (fr)</span>
                <span className="caption-text">{groupeSlider?.title_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre (ar)</span>
                <span className="caption-text">{groupeSlider?.title_ar}</span>
              </Col>
              <hr />
              <Col md="12">
                <span className="sub-text">Description (en)</span>
                <span className="caption-text" dangerouslySetInnerHTML={{ __html: groupeSlider?.description_en }}></span>
              </Col>
              <Col md="12">
                <span className="sub-text">Description (fr)</span>
                <span className="caption-text" dangerouslySetInnerHTML={{ __html: groupeSlider?.description_fr }}></span>
              </Col>
              <Col md="12">
                <span className="sub-text">Description (ar)</span>
                <span className="caption-text" dangerouslySetInnerHTML={{ __html: groupeSlider?.description_ar }}></span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Description courte (en)</span>
                <span className="caption-text">{groupeSlider?.short_desc_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description courte (fr)</span>
                <span className="caption-text">{groupeSlider?.short_desc_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description courte (ar)</span>
                <span className="caption-text">{groupeSlider?.short_desc_ar}</span>
              </Col>
              <hr />
              {groupeSlider?.btn_active ? (
                <Col md="6">
                  <span className="sub-text">Button Text (En)</span>
                  <span className={`caption-text text-green`}>{groupeSlider?.btn_text_en}</span>
                </Col>
              ) : null}
              {groupeSlider?.btn_active ? (
                <Col md="6">
                  <span className="sub-text">Button Text (Fr)</span>
                  <span className={`caption-text text-green`}>{groupeSlider?.btn_text_fr}</span>
                </Col>
              ) : null}
              {groupeSlider?.btn_active ? (
                <Col md="6">
                  <span className="sub-text">Button Text (Ar)</span>
                  <span className={`caption-text text-green`}>{groupeSlider?.btn_text_ar}</span>
                </Col>
              ) : null}
             {groupeSlider?.btn_active ? (
              <Col md="6">
                  <span className="sub-text">Button Link</span>
                  <Link className={`caption-text ${groupeSlider?.btn_active === 0 ? 'text-red' : 'text-green'}`} to={groupeSlider?.btn_link}>
                      {groupeSlider?.btn_link}
                  </Link>
              </Col>
              ) : null}
            <Col md="6">
              <span className="sub-text">Button Active</span>
              <Badge color={groupeSlider?.btn_active === 0 ? 'danger' : 'success'}>
                {groupeSlider?.btn_active === 0 ? 'Non' : 'Oui'}
              </Badge>
            </Col>
            </Row>

            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default GroupSliderDetails;
