import React, { useEffect, useState } from "react";
import {
  Icon,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Row,
} from "reactstrap";

const FilesDetails = ({modal, closeModal,viewId,loading,files}) => {

  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          files && (
            <>
              <Row>
                <Col md="12">
                  <h3>Details de fichier</h3>
                </Col>
                <br />
                <br />
                <br />
                <Col md="6">
                  <span className="sub-text">Nom du fichier</span>
                  <span className="caption-text">{files?.name}</span>
                </Col>
                <Col md="6">
                  <span className="sub-text">Description courte (En)</span>
                  <span className="caption-text">{files?.short_desc_en}</span>
                </Col>
                <Col md="6">
                  <span className="sub-text">Description courte (Fr)</span>
                  <span className="caption-text">{files?.short_desc_fr}</span>
                </Col>
                <Col md="6">
                  <span className="sub-text">Description courte (Ar)</span>
                  <span className="caption-text">{files?.short_desc_ar}</span>
                </Col>
                <hr />
                <Col md="6">
                  <span className="sub-text">Priorité</span>
                  <span className="caption-text">{files?.priority}</span>
                </Col>
                <Col md="6">
                  <span className="sub-text">Chemin du fichier</span>
                  <span className="caption-text">
                    <a href={files?.path} target="_blank">
                      {files?.path.split('/').pop()}
                    </a>
                  </span>
                </Col>
              </Row>
            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default FilesDetails;
