import React from "react";
import {
  Icon,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Row,
  Badge
} from "reactstrap";

const BlogDetails = ({modal, closeModal,blog,loading}) => {

  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          blog && (
            <>
           
           
           <Row>
              <Col md="12">
                <h3>Details de blog</h3>
              </Col>
              <br />
              <br />
              <br />
              <Col md="12" className="text-center">
                <img src={blog.image} alt="" style={{ maxWidth: "50%", height: "auto" }} />
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Titre (en)</span>
                <span className="caption-text">{blog.title_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre (fr)</span>
                <span className="caption-text">{blog.title_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre (ar)</span>
                <span className="caption-text">{blog.title_ar}</span>
              </Col>
              <hr />
              <Col md="12">
                <span className="sub-text">Description (en)</span>
                <span className="caption-text" dangerouslySetInnerHTML={{ __html: blog.description_en }}></span>
              </Col>
              <Col md="12">
                <span className="sub-text">Description (fr)</span>
                <span className="caption-text" dangerouslySetInnerHTML={{ __html: blog.description_fr }}></span>
              </Col>
              <Col md="12">
                <span className="sub-text">Description (ar)</span>
                <span className="caption-text" dangerouslySetInnerHTML={{ __html: blog.description_ar }}></span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Description courte (en)</span>
                <span className="caption-text">{blog.short_description_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description courte (fr)</span>
                <span className="caption-text">{blog.short_description_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description courte (ar)</span>
                <span className="caption-text">{blog.short_description_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Visible</span>
                <span className="caption-text">
                  {blog.show === true ? (
                    <Badge color="success">Oui</Badge>
                  ) : (
                    <Badge color="danger">Non</Badge>
                  )}
                </span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Titre de référencement (en)</span>
                <span className="caption-text">{blog.seo_title_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre de référencement(fr)</span>
                <span className="caption-text">{blog.seo_title_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre de référencement (ar)</span>
                <span className="caption-text">{blog.seo_title_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Description de référencement (en)</span>
                <span className="caption-text">{blog.seo_description_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description de référencement (fr)</span>
                <span className="caption-text">{blog.seo_description_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description de référencement (ar)</span>
                <span className="caption-text">{blog.seo_description_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Mots-clés de référencement</span>
                <span className="caption-text">{blog.seo_keywords}</span>
              </Col>
            </Row>

            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default BlogDetails;
