import React  from "react";
import {
  Icon,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Row,
} from "reactstrap";

const PartnerDetails = ({modal, closeModal,partner,loading}) => {


  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          partner && (
            <>
           
           
           <Row>
              <Col md="12">
                <h3>Details de partner</h3>
              </Col>
              <br />
              <br />
              <br />
              <Col md="12" className="text-center">
                <img src={partner.logo} alt="" style={{ maxWidth: "50%", height: "auto" }} />
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Nom (en)</span>
                <span className="caption-text">{partner.name_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Nom (fr)</span>
                <span className="caption-text">{partner.name_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Nom (ar)</span>
                <span className="caption-text">{partner.name_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Url</span>
                <span className="caption-text">{partner.url}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Priority</span>
                <span className="caption-text">{partner.priority}</span>
              </Col>
            </Row>

            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default PartnerDetails;
