import React from "react";
import { Icon, Col } from "../../../components/Component";
import { Modal, ModalBody, Row } from "reactstrap";
import moment from "moment";

const CommentsDetails = ({ modal, closeModal, comment, loading }) => {
  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          <>
            {comment && comment.length > 0 ? (
              <Row>
                <Col md="12">
                  <h3>Détails des Commentaires</h3>
                </Col>
                {comment.map((singleComment, index) => (
                  <React.Fragment key={index}>
                    <Col md="12" >
                      <h4 style={{marginTop:'15px'}}>Commentaire {index + 1}.</h4>
                    </Col>
                    <Col md="6">
                      <span className="sub-text" style={{ fontWeight: 'bold',fontSize:'0.7rem' }}>Nom:</span>
                      <span className="caption-text">{singleComment?.name}</span>
                    </Col>
                    <Col md="6">
                      <span className="sub-text" style={{ fontWeight: 'bold',fontSize:'0.7rem' }}>E-mail:</span>
                      <span className="caption-text">{singleComment?.email}</span>
                    </Col>
                    <Col md="12">
                      <span className="sub-text" style={{ fontWeight: 'bold',fontSize:'0.7rem' }} >Commentaire:</span>
                      <span className="caption-text">{singleComment?.message}</span>
                    </Col>
                    <Col md="6">
                      {/* <span className="sub-text" style={{ fontWeight: 'bold',fontSize:'0.7rem' }} >Créé à</span> */}
                      <span>{singleComment?.created_at ? moment(singleComment?.created_at).format('DD MMM YYYY') : '--'}</span>
                    </Col>
                    <hr />

                  </React.Fragment>
                ))}
              </Row>
            ) : (
              <p>Aucun commentaire à afficher.</p>
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CommentsDetails;
