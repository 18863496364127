import React, { useEffect, useState } from "react";
import {
  Icon,
  Button,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Form,
  Row,
  Badge
} from "reactstrap";
import { ProjectService } from "../../../_services/project.service";

const ProjectDetails = ({modal, closeModal,viewId,loading,project}) => {
  // const [project, setProject] = useState(null);
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   ProjectService.getById(viewId)
  //     .then((res) => {
  //       setProject(res.data);
  //     })
  //     .catch((error) => {
  //       console.error("Erreur lors de la récupération des détails de Project:", error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [viewId]);
  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          project && (
            <>
           
           
           <Row>
              <Col md="12">
                <h3>Details de Program</h3>
              </Col>
              <br />
              <br />
              <br />
              <Col md="12" className="text-center">
                <img src={project.image} alt="" style={{ maxWidth: "50%", height: "auto" }} />
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Nom (en)</span>
                <span className="caption-text">{project.name_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Nom (fr)</span>
                <span className="caption-text">{project.name_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Nom (ar)</span>
                <span className="caption-text">{project.name_ar}</span>
              </Col>
              <hr />
              <Col md="12">
                <span className="sub-text">Description (en)</span>
                <span className="caption-text" dangerouslySetInnerHTML={{ __html: project.description_en }}></span>
              </Col>
              <Col md="12">
                <span className="sub-text">Description (fr)</span>
                <span className="caption-text" dangerouslySetInnerHTML={{ __html: project.description_fr }}></span>
              </Col>
              <Col md="12">
                <span className="sub-text">Description (ar)</span>
                <span className="caption-text" dangerouslySetInnerHTML={{ __html: project.description_ar }}></span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Description courte (en)</span>
                <span className="caption-text">{project.short_description_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description courte (fr)</span>
                <span className="caption-text">{project.short_description_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description courte (ar)</span>
                <span className="caption-text">{project.short_description_ar}</span>
              </Col>
              <hr />
              {/* <Col md="6">
                <span className="sub-text">report</span>
                <span className="caption-text">
                  {project.report}
                </span>
              </Col> */}
                <Col md="6" >
                  <span className="sub-text">Partenaire (fr)</span>
                     {project.partners.map(partner => (
                        <span className="caption-text" style={{display: "block"}} key={partner.id} >- {partner.name_fr}</span>
                    ))}

                </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Titre de référencement (en)</span>
                <span className="caption-text">{project.seo_title_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre de référencement(fr)</span>
                <span className="caption-text">{project.seo_title_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre de référencement (ar)</span>
                <span className="caption-text">{project.seo_title_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Description de référencement (en)</span>
                <span className="caption-text">{project.seo_description_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description de référencement (fr)</span>
                <span className="caption-text">{project.seo_description_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description de référencement (ar)</span>
                <span className="caption-text">{project.seo_description_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Mots-clés de référencement</span>
                <span className="caption-text">{project.seo_keywords}</span>
              </Col>
            </Row>

            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default ProjectDetails;
