import React, { useEffect } from "react";
import {
  Icon,
  Button,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Form,
  Spinner
} from "reactstrap";
import { useForm } from "react-hook-form";

const FormModal = ({modal, closeModal, onSubmit, formData, setFormData, modalType,GridLoading}) => {

  useEffect(() => {
    reset(formData)
  }, [formData]);

  const { reset, register, handleSubmit, formState: { errors } } = useForm();


  const handleChange = prop => event => {
    setFormData({ ...formData, [prop]: event.target.value })
  }
  return (
    <>
   
          <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  closeModal();
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              {GridLoading ? (
                  <div className="text-center">
                    <Spinner color="primary" />
                  </div>
                ) : (
                <div className="p-2">
                  <h5 className="title">{modalType === "add" ? "Ajouter une catégorie" : "Modifier la catégorie"}</h5>
                  <div className="mt-4">
      
                      <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
                        <Col md="6">
                            <div className="form-group">
                              <label className="form-label">Nom (en)</label>
                              <input
                                type="text"
                                {...register('name_en', { required: "Ce champ est requis" })}
                                value={formData.name_en}
                                placeholder="Entrez le nom (en)"
                                onChange={handleChange('name_en')}
                                className="form-control" />
                              {errors.name_en && <span className="invalid">{errors.name_en.message}</span>}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <label className="form-label">Nom (fr)</label>
                              <input
                                type="text"
                                {...register('name_fr', { required: "Ce champ est requis" })}
                                value={formData.name_fr}
                                placeholder="Entrez le nom (fr)"
                                onChange={handleChange('name_fr')}
                                className="form-control" />
                              {errors.name_fr && <span className="invalid">{errors.name_fr.message}</span>}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <label className="form-label">Nom (ar)</label>
                              <input
                                type="text"
                                {...register('name_ar', { required: "Ce champ est requis" })}
                                value={formData.name_ar}
                                placeholder="Entrez le nom (ar)"
                                onChange={handleChange('name_ar')}
                                className="form-control" />
                              {errors.name_ar && <span className="invalid">{errors.name_ar.message}</span>}
                            </div>
                          </Col>
                          <hr />
                          <Col size="6">
                            <div className="form-group">
                              <label className="form-label">Titre de référencement (en)</label>
                              <input
                                {...register('seo_title_en', { required: "Ce champ est requis" })}
                                value={formData.seo_title_en}
                                placeholder="Votre Titre de référencement english"
                                onChange={handleChange('seo_title_en')}
                                className="form-control" />
                              {errors.seo_title_en && <span className="invalid">{errors.seo_title_en.message}</span>}
                            </div>
                          </Col>
                          <Col size="6">
                            <div className="form-group">
                              <label className="form-label">Titre de référencement (fr)</label>
                              <input
                                {...register('seo_title_fr', { required: "Ce champ est requis" })}
                                value={formData.seo_title_fr}
                                placeholder="Votre Titre de référencement francais"
                                onChange={handleChange('seo_title_fr')}
                                className="form-control" />
                              {errors.seo_title_fr && <span className="invalid">{errors.seo_title_fr.message}</span>}
                            </div>
                          </Col>
                          <Col size="6">
                            <div className="form-group">
                              <label className="form-label">Titre de référencement (ar)</label>
                              <input
                                {...register('seo_title_ar', { required: "Ce champ est requis" })}
                                value={formData.seo_title_ar}
                                placeholder="Votre Titre de référencement arabic"
                                onChange={handleChange('seo_title_ar')}
                                className="form-control" />
                              {errors.seo_title_ar && <span className="invalid">{errors.seo_title_ar.message}</span>}
                            </div>
                          </Col>
                          <hr />
                          <Col size="6">
                            <div className="form-group">
                              <label className="form-label">Description du référencement (en)</label>
                              <input
                                {...register('seo_description_en', { required: "Ce champ est requis" })}
                                value={formData.seo_description_en}
                                placeholder="Votre Description du référencement english"
                                onChange={handleChange('seo_description_en')}
                                className="form-control" />
                              {errors.seo_description_en && <span className="invalid">{errors.seo_description_en.message}</span>}
                            </div>
                          </Col>
                          <Col size="6">
                            <div className="form-group">
                              <label className="form-label">Description du référencement (fr)</label>
                              <input
                                {...register('seo_description_fr', { required: "Ce champ est requis" })}
                                value={formData.seo_description_fr}
                                placeholder="Votre Description du référencement francais"
                                onChange={handleChange('seo_description_fr')}
                                className="form-control" />
                              {errors.seo_description_fr && <span className="invalid">{errors.seo_description_fr.message}</span>}
                            </div>
                          </Col>
                          <Col size="6">
                            <div className="form-group">
                              <label className="form-label">Description du référencement (ar)</label>
                              <input
                                {...register('seo_description_ar', { required: "Ce champ est requis" })}
                                value={formData.seo_description_ar}
                                placeholder="Votre Description du référencement arabic"
                                onChange={handleChange('seo_description_ar')}
                                className="form-control" />
                              {errors.seo_description_ar && <span className="invalid">{errors.seo_description_ar.message}</span>}
                            </div>
                          </Col>
                          <hr />
                          <Col size="12">
                            <div className="form-group">
                              <label className="form-label">Mots-clés de référencement</label>
                              <input
                                {...register('seo_keywords', { required: "Ce champ est requis" })}
                                value={formData.seo_keywords}
                                placeholder="Vos Mots-clés de référencement"
                                onChange={handleChange('seo_keywords')}
                                className="form-control" />
                              {errors.seo_keywords && <span className="invalid">{errors.seo_keywords.message}</span>}
                            </div>
                          </Col>
                          <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                <Button color="primary" size="md" type="submit" disabled={GridLoading}>
                                  {GridLoading ?
                                      <Spinner size="sm" color="light" />
                                      :
                                      (modalType === "add" ? "Ajouter un Categorie" : "Modifier le Categorie")
                                  }
                              </Button>
                              </li>
                              <li>
                                <Button
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    closeModal();
                                  }}
                                  className="link link-light"
                                >
                                  Annuler
                                </Button>
                              </li>
                            </ul>
                          </Col>
                      </Form>
                  
                  </div>
                </div>
                )}
            </ModalBody>
          </Modal>
      
    </>
  );
};

export default FormModal;
