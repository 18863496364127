import React  from "react";
import {
  Icon,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import moment from "moment";

const CommentsDetails = ({modal, closeModal,comment,loading}) => {


  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          comment && (
            <>
           
           
           <Row>
              <Col md="12">
                <h3>Details de Comment</h3>
              </Col>
              <br />
              <br />
              <br />
              <Col md="6">
                <span className="sub-text">Nom</span>
                <span className="caption-text">{comment.name}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">E-mail</span>
                <span className="caption-text">{comment.email}</span>
              </Col>
              <Col md="12">
                <span className="sub-text">Message de comment</span>
                <span className="caption-text">{comment.message}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Titre de blog</span>
                <span className="caption-text">{comment?.blog?.title_fr}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Créé à</span>
                <span>{comment?.created_at ? moment(comment.created_at).format('DD MMM YYYY') : '--'}</span>
              </Col>
            </Row>

            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default CommentsDetails;
