import { authHeader, guestHeader, ApiConfigs } from '../_helpers';

export const ProjectService = {
    getAll ,
    getById,
    update,
    create,
    deleteProject,
    getGallery,
    storeGallery,
    deleteGalleryImage,
    getReports, 
    storeReport,
    deleteReport 
};


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.project.getAll}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.project.getById}`.replace('{id}', id), requestOptions).then(handleResponse);
}
function create(formData)  {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader()},
        body: formData 
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.project.create}`, requestOptions).then(handleResponse);
}

function update(id,formData)  {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader()},
        body: formData
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.project.update}`.replace('{id}', id), requestOptions).then(handleResponse);
}


function deleteProject(id)  {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.project.delete}`.replace('{id}', id), requestOptions).then(handleResponse);
}
function getGallery(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.project.getGallery}`.replace('{id}', id), requestOptions).then(handleResponse);
}
function storeGallery(id, formData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader()},
        body: formData 
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.project.storeGallery}`.replace('{id}', id), requestOptions).then(handleResponse);
}
function deleteGalleryImage(projectId, galleryId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.project.deleteGalleryImage}`
        .replace('{projectId}', projectId)
        .replace('{galleryId}', galleryId), requestOptions).then(handleResponse);
}

function getReports(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.project.getReports}`.replace('{id}', id), requestOptions).then(handleResponse);
}

function storeReport(id, formData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader()},
        body: formData 
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.project.storeReport}`.replace('{id}', id), requestOptions).then(handleResponse);
}
function deleteReport(projectId, reportId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.project.deleteReport}`
        .replace('{projectId}', projectId)
        .replace('{reportId}', reportId), requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user')
                window.location.href = '/auth-login'
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}