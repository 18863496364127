import React  from "react";
import {
  Icon,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import moment from "moment";

const DonationDetails = ({modal, closeModal,donation,loading}) => {


  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          donation && (
            <>
           
           
           <Row>
              <Col md="12">
                <h3>Details de Donation</h3>
              </Col>
              <br />
              <br />
              <br />
              <Col md="6">
                <span className="sub-text">Nom</span>
                <span className="caption-text">{donation.name}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Prenom</span>
                <span className="caption-text">{donation.last_name}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">E-mail</span>
                <span className="caption-text">{donation.email}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Montant</span>
                <span className="caption-text">{donation.amount}</span>
              </Col>
              <hr />
              <Col md="12">
                <span className="sub-text">Status</span>
                <span className="caption-text">{donation.status}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Créé à</span>
                <span>{donation?.created_at ? moment(donation.created_at).format('DD MMM YYYY') : '--'}</span>
              </Col>
            </Row>
            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default DonationDetails;
