import { authHeader, guestHeader, ApiConfigs } from '../_helpers';

export const CategoryService = {
    getAllCategories,
    getById,
    createCategory,
    updateCategory,
    deleteCategory,
    deleteSelectedCategories
};



function getAllCategories() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.category.getAll}`, requestOptions).then(handleResponse);
}
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.category.getById}`.replace('{id}', id), requestOptions).then(handleResponse);
}
function createCategory(formData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.category.create}`, requestOptions).then(handleResponse);
}

function updateCategory(id, formData) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.category.update}`.replace('{id}', id), requestOptions).then(handleResponse);
}

function deleteCategory(deleteId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.category.delete}`.replace('{id}', deleteId), requestOptions).then(handleResponse);
}
function deleteSelectedCategories(selectedIds) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ selectedIds })
    };
    return fetch(`${ApiConfigs.base_url}${ApiConfigs.category.deleteSelected}`, requestOptions).then(handleResponse);
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user')
                window.location.href = '/auth-login'
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}