import React, { useState, useEffect  } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Spinner,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  PaginationComponent,
  PreviewAltCard,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import moment from "moment";
import UserDetails from "./UserDetails";
import { UserService } from "../../../_services/user.service";

export const UserList = () => {
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    details: false,
  });
  const [deleteId, setDeletedId] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [GridLoading, setGridLoading] = useState(false)

  const getUser = () => {
    setGridLoading(true)
    UserService.getAll()
      .then(res => {
        setData(res.data)
        setFilteredData(res.data);
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setGridLoading(false)
      })
  }
  useEffect(() => {
    getUser()
  }, [])

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);


  const closeDeleteModal = () => {
    setModal({ delete: false })
  };
  const onDeleteClick = (id) => {
    setModal({ delete: true }, { add: false },{ edit: false },{details : false });
    setDeletedId(id);
  };

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const onViewClick = (id) => {
    setModal({details : true });
    setLoading(true);
    UserService.getById(id)
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des détails de User:", error);
      })
      .finally(() => {
        setLoading(false);
      });
      
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    setGridLoading(true); 
    
    const filtered = data.filter(item =>
      (item.name && item.name.toLowerCase().includes(keyword)) ||
      (item.email && item.email.toLowerCase().includes(keyword)) ||
      (item.created_at && moment(item.created_at).format('DD MMM YYYY').toLowerCase().includes(keyword))
    );
    setFilteredData(filtered);
    
    setGridLoading(false); 
  };
  

  return (
    <React.Fragment>
      <Head title="User List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> Liste des utilisateurs</BlockTitle>
              <BlockDes className="text-soft">Vous avez au total {data.length} utilisateurs.</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                  <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="search"
                          // onChange={(e) => onFilterChange(e)}
                          onChange={handleSearch}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="nk-tb-list is-separate nk-tb-ulist">
            <DataTableHead className="nk-tb-item nk-tb-head">
              <DataTableRow>
                <span className="sub-text">Nom</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">E-mail</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Créé à</span>
              </DataTableRow>
              
              <DataTableRow className="nk-tb-col-tools text-end">
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="btn btn-xs btn-trigger btn-icon dropdown-toggle me-n1">
                  </DropdownToggle>
                 
                </UncontrolledDropdown>
              </DataTableRow>
            </DataTableHead>
            {GridLoading ? (
                <DataTableItem className={'text-center height-100'}>
                    <DataTableRow className={'spinner-container'}>
                      <Spinner color="primary" />
                    </DataTableRow>
                </DataTableItem>
            ) : 
            currentItems.length > 0
              ? currentItems.map((item) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow>
                            <span className="title">{item?.name ||'--'}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{item?.email || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="mb">
                      <span>{item?.created_at ? moment(item.created_at).format('DD MMM YYYY') : '--'}</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#view"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        onViewClick(item.id);
                                      }}
                                    >
                                      <Icon name="eye"></Icon>
                                      <span>Voir les utilisateurs </span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              : null}
          </div>
          <PreviewAltCard>
            {filteredData.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">Aucun utilisateur trouvé</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>

        <UserDetails modal={modal.details}  closeModal={closeDeleteModal} user={user} loading={loading} />
      </Content>
      
    </React.Fragment>

    
  );
};

export default UserList;
