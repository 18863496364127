import React, { useState, useEffect  } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import ProductH from "../../../images/product/h.png";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Spinner,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  PaginationComponent,
  PreviewAltCard,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import FormModal from "./FormModal";
import DeleteModal from "./DeleteModal";
import moment from 'moment';


import SliderDetails from "./SliderDetails";
import { SliderService } from "../../../_services/slider.service";

export const SliderList = () => {
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    delete :false,
    details: false,
  });

  const [imagePreview, setImagePreview] = useState('');
 


  const [editId, setEditedId] = useState();
  const [deleteId, setDeletedId] = useState();
  const [viewId, setViewId] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [ErreurPriority, setErreurPriority] = useState('');

  const [GridLoading, setGridLoading] = useState(false)

  const getAll = () => {
    setGridLoading(true)
    SliderService.getAll()
      .then(res => {
        setData(res.data)
        setFilteredData(res.data);
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setGridLoading(false)
      })
  }
  useEffect(() => {
    getAll()
  }, [])
const [formData, setFormData] = useState({
  title_en: "",
  title_fr: "",
  title_ar: "",
  image: null,
  sub_title_en: "",
  sub_title_fr: "",
  sub_title_ar: "",
  priority: null,
  btn_text:"",
  btn_link:"",
  btn_active:0
});

const [editFormData, setEditFormData] = useState({
  title_en: "",
  title_fr: "",
  title_ar: "",
  image: null,
  sub_title_en: "",
  sub_title_fr: "",
  sub_title_ar: "",
  priority: null,
  btn_text:"",
  btn_link:"",
  btn_active:0
});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);

  // function to reset the form
  const resetForm = () => {
    setFormData({
      title_en: "",
      title_fr: "",
      title_ar: "",
      image: null,
      sub_title_en: "",
      sub_title_fr: "",
      sub_title_ar: "",
      priority: null,
      btn_text:"",
      btn_link:"",
      btn_active:0
    });
  };
  const [previousImageURL, setPreviousImageURL] = useState('');

  const closeModal = () => {
    setModal({ add: false })
    resetForm();
    setPreviousImageURL("")
    setImagePreview("")
    setErreurPriority("")

  };

  const closeEditModal = () => {
    setModal({ edit: false })
    resetForm();
    setImagePreview("")
    setPreviousImageURL("")
    setErreurPriority("")
  };
  const closeDeleteModal = () => {
    setModal({ delete: false })
    resetForm();
    setImagePreview("")
    setErreurPriority("")
  };


  const onFormSubmit = () => {    
    setGridLoading(true); 

    const { title_en, title_fr, title_ar, sub_title_en, sub_title_fr, sub_title_ar, priority, image,btn_text,btn_link,btn_active } = formData;


    const formDataToSend = new FormData();
    formDataToSend.append('title_en', title_en);
    formDataToSend.append('title_fr', title_fr);
    formDataToSend.append('title_ar', title_ar);
    formDataToSend.append('sub_title_en', sub_title_en);
    formDataToSend.append('sub_title_fr', sub_title_fr);
    formDataToSend.append('sub_title_ar', sub_title_ar);
    formDataToSend.append('priority', priority);
    formDataToSend.append('btn_text', btn_text);
    formDataToSend.append('btn_link', btn_link);
    formDataToSend.append('btn_active', btn_active);
    if (image) {
        formDataToSend.append('image', image);
    }

    SliderService.create(formDataToSend)
        .then(res => {
            getAll();
            resetForm();
            setModal({ add: false });
            setImagePreview("")
            setPreviousImageURL("")

        })
        .catch(error => {
            console.error('Erreur lors de la soumission de slider :', error);
            setErreurPriority(error)
        })
        .finally(() => {
            setGridLoading(false); 
        });
};


  // submit function to update a new item
  const onEditSubmit = () => {
    setGridLoading(true);
  
    const {
      title_en, title_fr, title_ar, sub_title_en, sub_title_fr, sub_title_ar, priority, image,btn_text,btn_link,btn_active} = editFormData;
  

    const formDataToSend = new FormData();
    formDataToSend.append('title_en', title_en);
    formDataToSend.append('title_fr', title_fr);
    formDataToSend.append('title_ar', title_ar);
    formDataToSend.append('sub_title_en', sub_title_en);
    formDataToSend.append('sub_title_fr', sub_title_fr);
    formDataToSend.append('sub_title_ar', sub_title_ar);
    formDataToSend.append('priority', priority);
    formDataToSend.append('btn_active', btn_active);
  
    if (btn_active) {
      formDataToSend.append('btn_text', btn_text);
      formDataToSend.append('btn_link', btn_link);
    } else {
      formDataToSend.append('btn_text', '');
      formDataToSend.append('btn_link', '');
    }

    if (image && image instanceof File) {
      formDataToSend.append('image', image);
    }
  
    SliderService.update(editId, formDataToSend)
      .then(res => {
        console.log('Slider mise à jour avec succès !', res);
        getAll();
        setModal({ edit: false });
        resetForm();
        setImagePreview("")
        setPreviousImageURL("")

      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour de Slider :', error);
        setErreurPriority(error)
      })
      .finally(() => {
        setGridLoading(false);
      });
  };
  



  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setPreviousImageURL(item.image);
        setEditFormData({
          title_en: item.title_en,
          title_fr: item.title_fr,
          title_ar: item.title_ar,
          sub_title_en: item.sub_title_en,
          sub_title_fr: item.sub_title_fr,
          sub_title_ar: item.sub_title_ar,
          priority: item.priority,
          image:item.image,
          btn_text:item.btn_text,
          btn_link:item.btn_link,
          btn_active:item.btn_active,
        });
        setEditedId(id);
        setModal({ edit: true, add: false });
      }
    });
  };
  

  const onDeleteClick = (id) => {
    setModal({ delete: true }, { add: false },{ edit: false },{details : false });
    setDeletedId(id);
  };
  const onViewClick = (id) => {
    setModal({details : true },{ delete: false }, { add: false },{ edit: false });
    setViewId(id);
  };
  const confirmDelete = () => {
    setGridLoading(true); 
    
    SliderService.deleteSlider(deleteId)
      .then(() => {
        getAll(); 
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de la Slider :', error);
      })
      .finally(() => {
        setGridLoading(false); 
        closeDeleteModal(); 
      });
  };
  

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Function to handle search
  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    setGridLoading(true); 
    
    const filtered = data.filter(item =>
      item.title_fr.toLowerCase().includes(keyword) ||
      item.sub_title_fr.toLowerCase().includes(keyword) ||
      item.priority.toLowerCase().includes(keyword) ||
      moment(item.created_at).format('DD MMM YYYY').toLowerCase().includes(keyword)
    );
    setFilteredData(filtered);
    
    setGridLoading(false); 
  };


  return (
    <React.Fragment>
      <Head title="Blog List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> Slider</BlockTitle>
              <BlockDes className="text-soft">Vous avez au total {data.length} Slider.</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                  <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="search"
                          // onChange={(e) => onFilterChange(e)}
                          onChange={handleSearch}
                        />
                      </div>
                    </li>
                    <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                      <Button color="primary">
                        <Icon name="plus"></Icon>
                        <span>Ajouter des Slider</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="nk-tb-list is-separate nk-tb-ulist">
            <DataTableHead className="nk-tb-item nk-tb-head">
              <DataTableRow>
                <span className="sub-text">Image</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Titre (fr)</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Sous-titre (fr) </span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Priority </span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Créé à</span>
              </DataTableRow>
              
              <DataTableRow className="nk-tb-col-tools text-end">
              <span className="sub-text">Action</span>
              </DataTableRow>
            </DataTableHead>
            {GridLoading ? (
                <DataTableItem className={'text-center height-100'}>
                  <DataTableRow className={'spinner-container'}>
                    <Spinner color="primary" />
                  </DataTableRow>
                </DataTableItem>
            ) : 
            currentItems.length > 0
              ? currentItems.map((item) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow size="sm">
                        <span className="tb-product">
                          <img src={item.image ? item.image : ProductH} alt="product" className="thumb" />
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{item?.title_fr || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                         <span>{item?.sub_title_fr || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                         <span>{item?.priority || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="mb">
                        <span>{item?.created_at ? moment(item.created_at).format('DD MMM YYYY') : '--'}</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#view"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      onViewClick(item.id);
                                    }}
                                  >
                                    <Icon name="eye"></Icon>
                                    <span>Voir Slider </span>
                                  </DropdownItem>
                                </li>
                                  <li onClick={() => onEditClick(item.id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Modifier</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => onDeleteClick(item.id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#delete"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                     <Icon name="trash"></Icon>
                                      <span>Supprimer</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              : null}
          </div>
          <PreviewAltCard>
            {filteredData.length ||data.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">Aucune Slider trouvée</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>


        <FormModal modal={modal.add} modalType="add" formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit}  setImagePreview={setImagePreview} imagePreview={imagePreview} previousImageURL={previousImageURL} GridLoading={GridLoading} ErreurPriority={ErreurPriority} />
        <FormModal modal={modal.edit} modalType="edit" formData={editFormData} setFormData={setEditFormData} closeModal={closeEditModal} onSubmit={onEditSubmit}  setImagePreview={setImagePreview} imagePreview={imagePreview} previousImageURL={previousImageURL} GridLoading={GridLoading} ErreurPriority={ErreurPriority} />
        <DeleteModal modal={modal.delete}  closeModal={closeDeleteModal} onSubmit={confirmDelete} GridLoading={GridLoading} />
        <SliderDetails modal={modal.details}  closeModal={closeDeleteModal} viewId={viewId} />
      </Content>
      
    </React.Fragment>

    
  );
};

export default SliderList;