import { authHeader, guestHeader, ApiConfigs } from '../_helpers';

export const UserService = {
    getAll ,
    getById,
};


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.users.getAll}`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.users.getById}`.replace('{id}', id), requestOptions).then(handleResponse);
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user')
                window.location.href = '/auth-login'
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}