import React from "react";
import { Card, Button, Spinner } from "reactstrap";

const DataCard = ({ title, description, number, number_name, button_text, href, illustration, loading }) => {
  return (
    <Card className="data-card">
      <div className="data-card-inner">
        <div className="data-card-title">
          <h6>{title}</h6>
        </div>
        <div className="data">
          <div className="data-group">
            <div className="description">{description}</div>
            {/* Add a CSS class to the parent div */}
            {
              number && number != '--' && 
                <div className="data-details">
                  <div className="amount">{loading ? <Spinner size="lg" type="grow" color="primary" /> : number}</div>
                  <div className="number-name">{number_name}</div>
                </div>
            }
          </div>
          {illustration && <img className="illustration-image" src={illustration} alt="Illustration" />}
        </div>
        {button_text && (
           <a href={href}>
            <Button color="primary">{button_text}</Button>
          </a>
        )}
      </div>
    </Card>
  );
};

export default DataCard;
