import React, { useCallback, useEffect, useState } from "react";
import {
  Icon,
  Button,
  Col,
  RSelect
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Form,
  Spinner
} from "reactstrap";
import { useForm } from "react-hook-form";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDropzone } from 'react-dropzone';

const FormModal = ({modal, closeModal, onSubmit, formData, setFormData, modalType,partnerOptions,setImagePreview,imagePreview,previousImageURL,GridLoading}) => {
  useEffect(() => {
    reset(formData)
  }, [formData]);

  const { reset, register, handleSubmit, formState: { errors } } = useForm();


  const handleChange = prop => event => {
    setFormData({ ...formData, [prop]: event.target.value })
  }
  useEffect(() => {
    reset(formData);
    if (formData.image instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(formData.image);
    } else if (typeof formData.image === 'string') {
        setImagePreview("");
    }
}, [formData]);


  const onDrop = useCallback((acceptedFiles) => {
    setFormData({ ...formData, image: acceptedFiles[0] });
  }, [formData, setFormData]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });


  const [btnActive, setBtnActive] = useState(formData.btn_active || false);

    useEffect(() => {
      reset(formData);
      setBtnActive(formData.btn_active || false);
    }, [formData]);

    const handleSwitchChange = () => {
      const newValue = btnActive ? 0 : 1; 
      setBtnActive(!btnActive);
      setFormData({ ...formData, btn_active: newValue });
    };

  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{modalType === "add" ? "Ajouter une Annonce" : "Modifier l'Annonce"}</h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
            <Col md="12">
              { formData.image ? 
                <img src={previousImageURL} alt="Current" className="img-preview" /> : null }
            </Col>
            <Col md="12">
                <div className="form-group">
                <label className="form-label">{formData.image ? 'Si vous voulez changer l\'image' : 'Image'}</label>
                    <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        {imagePreview ? (
                            <img src={imagePreview} alt="Preview" className="img-preview" />
                        ) : (
                            <p>Glissez et déposez une image ici, ou cliquez pour sélectionner une image</p>
                        )}
                    </div>
                </div>
            </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Nom (en)</label>
                  <input
                    type="text"
                    {...register('name_en', { required: "Ce champ est requis" })}
                    value={formData.name_en}
                    placeholder="Entrez le Nom (en)"
                    onChange={handleChange('name_en')}
                    className="form-control" />
                  {errors.name_en && <span className="invalid">{errors.name_en.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Nom (fr)</label>
                  <input
                    type="text"
                    {...register('name_fr', { required: "Ce champ est requis" })}
                    value={formData.name_fr}
                    placeholder="Entrez le nom (fr)"
                    onChange={handleChange('name_fr')}
                    className="form-control" />
                  {errors.name_fr && <span className="invalid">{errors.name_fr.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Nom (ar)</label>
                  <input
                    type="text"
                    {...register('name_ar', { required: "Ce champ est requis" })}
                    value={formData.name_ar}
                    placeholder="Entrez le Nom (ar)"
                    onChange={handleChange('name_ar')}
                    className="form-control" />
                  {errors.name_ar && <span className="invalid">{errors.name_ar.message}</span>}
                </div>
              </Col>
              <hr />
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Description courte (en)</label>
                  <textarea
                    type="text"
                    {...register('short_description_en')}
                    value={formData.short_description_en}
                    placeholder="Entrez la description courte (en)"
                    onChange={handleChange('short_description_en')}
                    className="form-control" />
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Description courte (fr)</label>
                  <textarea
                    type="text"
                    {...register('short_description_fr')}
                    value={formData.short_description_fr}
                    placeholder="Entrez la description courte (fr)"
                    onChange={handleChange('short_description_fr')}
                    className="form-control" />
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Description courte (ar)</label>
                  <textarea
                    type="text"
                    {...register('short_description_ar')}
                    value={formData.short_description_ar}
                    placeholder="Entrez la description courte (ar)"
                    onChange={handleChange('short_description_ar')}
                    className="form-control" />
                </div>
              </Col>
              <hr />
              <Col md="12">
              <div className="form-group">
                <label className="form-label">Description (en)</label>
                <Editor
                  editorState={formData.description_en}
                  onEditorStateChange={(editorState) => setFormData({ ...formData, description_en: editorState })}
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor-content"
                />
                {errors.description_en && <span className="invalid">{errors.description_en.message}</span>}
              </div>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label className="form-label">Description (fr)</label>
                <Editor
                  editorState={formData.description_fr}
                  onEditorStateChange={(editorState) => setFormData({ ...formData, description_fr: editorState })}
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor-content"
                />
                {errors.description_fr && <span className="invalid">{errors.description_fr.message}</span>}
              </div>
            </Col>
            <Col md="12">
              <div className="form-group">
                <label className="form-label">Description (ar)</label>
                <Editor
                  editorState={formData.description_ar}
                  onEditorStateChange={(editorState) => setFormData({ ...formData, description_ar: editorState })}
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor-content"
                />
                {errors.description_ar && <span className="invalid">{errors.description_ar.message}</span>}
              </div>
            </Col>
              <hr />

              {/* <Col md="6">
              <div className="form-group">
                  <label className="form-label">Report</label>
                  <input
                    {...register('report', { required: "Ce champ est requis" })}
                    value={formData.report}
                    placeholder="Entrez Votre report"
                    onChange={handleChange('report')}
                    className="form-control" />
                  {errors.report && <span className="invalid">{errors.report.message}</span>}
                </div>
                </Col> */}
                <Col size="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="category">
                      Partenaires
                    </label>
                    <div className="form-control-wrap">
                      <RSelect
                        name="partners"
                        options={partnerOptions}
                        onChange={(selectedOption) => {
                          const selectedValues = selectedOption.map(option => option.value);
                          setFormData({ ...formData, partner_ids: selectedValues });
                        }}
                        isMulti
                        value={formData.partner_ids ? partnerOptions.filter(option => formData.partner_ids.includes(option.value)) : []}
                      />
                      {errors && errors.partner_ids && <span className="invalid">{errors.partner_ids.message}</span>}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Activer le deuxième bouton</label>
                    <div className="d-flex align-items-center"> 
                      <div className="mr-2"> 
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch"
                            checked={btnActive}
                            onChange={handleSwitchChange}
                          />
                          <label className="custom-control-label" htmlFor="customSwitch">
                            {btnActive ? "Active" : "Inactive"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">{btnActive ? 'Titre du deuxième bouton (En)' : ""}</label>
                    <input
                      type="text"
                      {...register('btn_text_en')}
                      value={formData.btn_text_en}
                      placeholder="Entrez le Titre du deuxième bouton (EN)"
                      onChange={handleChange('btn_text_en')}
                      className="form-control"
                      style={{ display: btnActive ? 'block' : 'none' }} 
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">{btnActive ? 'Titre du deuxième bouton (fr) ' : ""}</label>
                    <input
                      type="text"
                      {...register('btn_text_fr')}
                      value={formData.btn_text_fr}
                      placeholder="Entrez le Titre du deuxième bouton (fr)"
                      onChange={handleChange('btn_text_fr')}
                      className="form-control"
                      style={{ display: btnActive ? 'block' : 'none' }} 
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">{btnActive ? 'Titre du deuxième bouton (ar) ' : ""}</label>
                    <input
                      type="text"
                      {...register('btn_text_ar')}
                      value={formData.btn_text_ar}
                      placeholder="Entrez le Titre du deuxième bouton (ar)"
                      onChange={handleChange('btn_text_ar')}
                      className="form-control"
                      style={{ display: btnActive ? 'block' : 'none' }} 
                    />
                  </div>
                </Col>
                  <hr />
                  <Col md="12">
                    <div className="form-group" style={{ display: btnActive ? 'block' : 'none' }}> 
                      <label className="form-label">Lien pour le deuxième bouton</label>
                      <input
                        type="text"
                        {...register('btn_link')}
                        value={formData.btn_link}
                        placeholder="Entrez le Lien du deuxième bouton"
                        onChange={handleChange('btn_link')}
                        className="form-control"
                      />
                    </div>
                  </Col>
                <h2  style={{textAlign:'center'}}>S e o</h2>
                <hr />
              <Col size="6">
                <div className="form-group">
                  <label className="form-label">Titre de référencement (en)</label>
                  <input
                    {...register('seo_title_en', { required: "Ce champ est requis" })}
                    value={formData.seo_title_en}
                    placeholder="Votre Titre de référencement english"
                    onChange={handleChange('seo_title_en')}
                    className="form-control" />
                  {errors.seo_title_en && <span className="invalid">{errors.seo_title_en.message}</span>}
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label">Titre de référencement (fr)</label>
                  <input
                    {...register('seo_title_fr', { required: "Ce champ est requis" })}
                    value={formData.seo_title_fr}
                    placeholder="Votre Titre de référencement francais"
                    onChange={handleChange('seo_title_fr')}
                    className="form-control" />
                  {errors.seo_title_fr && <span className="invalid">{errors.seo_title_fr.message}</span>}
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label">Titre de référencement (ar)</label>
                  <input
                    {...register('seo_title_ar', { required: "Ce champ est requis" })}
                    value={formData.seo_title_ar}
                    placeholder="Votre Titre de référencement arabic"
                    onChange={handleChange('seo_title_ar')}
                    className="form-control" />
                  {errors.seo_title_ar && <span className="invalid">{errors.seo_title_ar.message}</span>}
                </div>
              </Col>
              <hr />
              <Col size="6">
                <div className="form-group">
                  <label className="form-label">Description du référencement (en)</label>
                  <input
                    {...register('seo_description_en', { required: "Ce champ est requis" })}
                    value={formData.seo_description_en}
                    placeholder="Votre Description du référencement english"
                    onChange={handleChange('seo_description_en')}
                    className="form-control" />
                  {errors.seo_description_en && <span className="invalid">{errors.seo_description_en.message}</span>}
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label">Description du référencement (fr)</label>
                  <input
                    {...register('seo_description_fr', { required: "Ce champ est requis" })}
                    value={formData.seo_description_fr}
                    placeholder="Votre Description du référencement francais"
                    onChange={handleChange('seo_description_fr')}
                    className="form-control" />
                  {errors.seo_description_fr && <span className="invalid">{errors.seo_description_fr.message}</span>}
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label">Description du référencement (ar)</label>
                  <input
                    {...register('seo_description_ar', { required: "Ce champ est requis" })}
                    value={formData.seo_description_ar}
                    placeholder="Votre Description du référencement arabic"
                    onChange={handleChange('seo_description_ar')}
                    className="form-control" />
                  {errors.seo_description_ar && <span className="invalid">{errors.seo_description_ar.message}</span>}
                </div>
              </Col>
              <Col size="6">
                <div className="form-group">
                  <label className="form-label">Mots-clés de référencement</label>
                  <input
                    {...register('seo_keywords', { required: "Ce champ est requis" })}
                    value={formData.seo_keywords}
                    placeholder="Vos Mots-clés de référencement"
                    onChange={handleChange('seo_keywords')}
                    className="form-control" />
                  {errors.seo_keywords && <span className="invalid">{errors.seo_keywords.message}</span>}
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {GridLoading ?
                        <Spinner size="sm" color="light" />
                        :
                        modalType === "add" ? "Ajouter une Annonce" : "Modifier l'Annonce"
                      }
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Annuler
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>

    );
  };

export default FormModal;
