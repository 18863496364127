import React  from "react";
import {
  Icon,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Row,
} from "reactstrap";

const CategoriesDetails = ({modal, closeModal,categories,loading}) => {


  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          categories && (
            <>
           
           
           <Row>
              <Col md="12">
                <h3>Details de Categories</h3>
              </Col>
              <br />
              <br />
              <br />
              <Col md="6">
                <span className="sub-text">Nom (en)</span>
                <span className="caption-text">{categories.name_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Nom (fr)</span>
                <span className="caption-text">{categories.name_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Nom (ar)</span>
                <span className="caption-text">{categories.name_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Titre de référencement (en)</span>
                <span className="caption-text">{categories.seo_title_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre de référencement (fr)</span>
                <span className="caption-text">{categories.seo_title_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Titre de référencement (ar)</span>
                <span className="caption-text">{categories.seo_title_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Description du référencement (en)</span>
                <span className="caption-text">{categories.seo_description_en}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description du référencement (fr)</span>
                <span className="caption-text">{categories.seo_description_fr}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Description du référencement (ar)</span>
                <span className="caption-text">{categories.seo_description_ar}</span>
              </Col>
              <hr />
              <Col md="6">
                <span className="sub-text">Mots-clés de référencement</span>
                <span className="caption-text">{categories.seo_keywords}</span>

              </Col>
            </Row>

            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default CategoriesDetails;
