//authHeader() function will be used in the future for common authenticated user header
export function authHeader() {
    let token = localStorage.getItem('accessToken');
    let language = localStorage.getItem('lang');

    if (token) {
        return {
            'Authorization': 'Bearer ' + token,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Language': language??'en',
        };
    } else {
        return {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Language': language??'en',
        };
    }
}

export function guestHeader() {
    let language = localStorage.getItem('lang');
    return {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Language': language??'en',
    };
}