import React, { useCallback } from "react";
import {
  Icon,
  Button,
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Form,
} from "reactstrap";
import { useForm } from "react-hook-form";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDropzone } from 'react-dropzone';

const FormModalGallery = ({ modal, closeModal, onSubmit, projectgallery ,setGallery,gallery,deleteImage,modalType}) => {

  const { handleSubmit } = useForm();

  const onImageDrop = useCallback(acceptedFiles => {
    const newImages = acceptedFiles.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));

    setGallery(prevState => ({
      ...prevState,
      image: [...(prevState?.image ?? []), ...newImages]
    }));
  }, [setGallery]);

  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps, isDragActive: isImageDragActive } = useDropzone({ onDrop: onImageDrop });

  const handleDeleteImage = (index, imageId) => {
    if (imageId) {
      const imageToDelete = projectgallery.find(image => image.id === imageId);
      if (imageToDelete) {
        deleteImage(imageToDelete.project_id, imageId);
      }
    } else {
      setGallery(prevState => ({
        ...prevState,
        image: prevState.image.filter((_, idx) => idx !== index)
      }));
    }
  };

  const onFileDrop = useCallback(acceptedFiles => {
    const newFiles = acceptedFiles.map(file => ({
      file,
      name: file.name
    }));

    setGallery(prevState => ({
      ...prevState,
      files: [...(prevState?.files ?? []), ...newFiles]
    }));
  }, [setGallery]);

  const { getRootProps: getFileRootProps, getInputProps: getFileInputProps, isDragActive: isFileDragActive } = useDropzone({ onDrop: onFileDrop });

  // const handleDeleteFile = (index) => {
  //   setGallery(prevState => ({
  //     ...prevState,
  //     files: prevState.files.filter((_, idx) => idx !== index)
  //   }));
  // };
  const handleDeleteFile = (index, reportId) => {
    if (reportId) {
      const filleToDelete = projectgallery.find(file => file.id === reportId);
      if (filleToDelete) {
        deleteImage(filleToDelete.project_id, reportId);
      }
    } else {
      setGallery(prevState => ({
        ...prevState,
        files: prevState.files.filter((_, idx) => idx !== index)
      }));
    }
  };

  // const handleDownload = (fileUrl) => {
  //   const link = document.createElement('a');
  //   link.href = fileUrl;
  //   link.setAttribute('download', ''); 
  //   link.style.display = 'none'; // Hide the link
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleOpen = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };
  const extractFileName = (url) => {
    const parts = url.split('/');
    return parts[parts.length - 1];
  };
  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{modalType === 'gallery' ? 'Ajouter des Images' : 'Ajouter des Files'}</h5>
          <div className="old-images">
          {modalType === 'gallery' ?
          <>
            {projectgallery && projectgallery.map((image, index) => (
              <div key={index} className="old-image" style={{ position: 'relative' }}>
                <img src={image.image} alt="Ancienne image de la galerie" style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px', marginBottom: '10px' }} />
                <Button color="danger" className="delete-icon" onClick={() => deleteImage(image.project_id, image.id)} style={{ position: 'absolute', top: '5px', right: '5px' }}>
                  <Icon name="trash"></Icon>
                </Button>
              </div>
            ))}
            </>
            :
            <>
           {projectgallery && projectgallery.map((file, index) => (
                <div key={index} className="old-file" style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                  <div style={{ flexGrow: 1 }}>
                    <p style={{ marginRight: '10px' }}>Nom de fichier: {file.file_name}</p>
                  </div>
                  <div>
                    <Button color="secondary" onClick={() => handleOpen(file.file_path)} style={{ marginRight: '10px' }}>
                      <Icon name="eye-alt-fill"></Icon>
                    </Button>
                    <Button color="danger" onClick={() => deleteImage(file.project_id, file.id)}>
                      <Icon name="trash"></Icon>
                    </Button>
                  </div>
                </div>
              ))}
             </>
          }
          </div>
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
              { modalType === 'gallery' ?
                <>
                  <div {...getImageRootProps()} className={`dropzone ${isImageDragActive ? 'active' : ''}`}>
                    <input {...getImageInputProps()} />
                    <p>Glissez et déposez des images ici ou cliquez pour sélectionner des images</p>
                  </div>
                  <div className="added-images">
                    {gallery?.image && gallery?.image.map((addedImage, index) => (
                      <div key={index} className="added-image" style={{ position: 'relative' }}>
                        <img src={addedImage.preview} alt="Image ajoutée" style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px', marginBottom: '10px' }} />
                        <Button color="danger" className="delete-icon" onClick={() => handleDeleteImage(index)}>
                          <Icon name="trash"></Icon>
                        </Button>
                      </div>
                    ))}
                  </div>
                </>
                :
                <>
                  <div {...getFileRootProps()} className={`dropzone ${isFileDragActive ? 'active' : ''}`}>
                    <input {...getFileInputProps()} multiple />
                    <p>Glissez et déposez des fichiers ici ou cliquez pour sélectionner des fichiers</p>
                  </div>

                  <div className="added-files">
                    {gallery?.files && gallery?.files.map((file, index) => (
                      <div key={index} className="added-file" style={{ position: 'relative' }}>
                        <p>Selected File: {file.name}</p>
                        <Button color="danger" className="delete-icon" onClick={() => handleDeleteFile(index)}>
                          <Icon name="trash"></Icon>
                        </Button>
                      </div>
                    ))}
                  </div>
                </>
              }
              <div className="d-flex justify-content-end">
                <Button color="secondary" onClick={closeModal}>Close</Button>
                <Button color="primary" type="submit" className="ml-2">Ajouter</Button>
            </div>
            </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FormModalGallery;
