import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <span style={{ display: 'inline' }}>
            &copy; 2024 Tous droits réservés par&nbsp;
            <Link to="https://WEYOUTH.com" style={{ display: 'inline' }}>
              WEYOUTH
            </Link>
          </span>
          <div className="nk-footer-links" style={{ marginTop: '10px' }}>
            <ul className="nav nav-sm">
              <li className="nav-item">
                <span>Développé par </span>
                <Link to="https://provestasoft.com" className="nav-link">
                  ProvestaSoft
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to={`${process.env.PUBLIC_URL}/pages/faq`} className="nav-link">
                  Privacy
                </Link>
              </li>
              <li className="nav-item">
                <Link to={`${process.env.PUBLIC_URL}/pages/terms-policy`} className="nav-link">
                  Help
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
