import { authHeader, guestHeader, ApiConfigs } from '../_helpers';

export const AdminService = {
    login,
    logout 
};



function login(formData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...guestHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.admin.login}`, requestOptions).then(handleResponse);
}

function logout() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.admin.logout}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user')
                window.location.href = '/auth-login'
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}