import React, { useState, useEffect, useCallback  } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Spinner,
  Col,
  Form,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Button,
 
} from "../../../components/Component";


import { AboutService } from "../../../_services/about.service";
import { useDropzone } from 'react-dropzone';
import { useForm } from "react-hook-form";
import classnames from "classnames";
import ReactCountryFlag from "react-country-flag";
export const AboutList = () => {



  const [activeTab, setActiveTab] = useState("1");
  const [activeTabA, setActiveTabA] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    if (activeTabA !== tab) setActiveTabA(tab);
  };
  const [imagePreview, setImagePreview] = useState('');
  const [image2Preview, setImage2Preview] = useState('');
  const [image3Preview, setImage3Preview] = useState('');
  const [imagebgPreview, setImageBgPreview] = useState('');
 

  const [data, setData] = useState([]);

  const [GridLoading, setGridLoading] = useState(false)

  const getAll = () => {
    setGridLoading(true)
    AboutService.getAll()
      .then(res => {
        setData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setGridLoading(false)
      })
  }
  useEffect(() => {
    getAll()
  }, [])

const [editFormData, setEditFormData] = useState({
  title_en: "",
  title_fr: "",
  title_ar: "",
  description_en:"",
  description_fr:"",
  description_ar:"",
  raised_people:0,
  volunteer:0,
  years_of_experience:0,
  projects_completed:0,
  image_video_bg: null,
  image_one: null,
  image_two: null,
  image_three: null,
  our_mission_en: "",
  our_mission_fr: "",
  our_mission_ar: "",
  our_vision_en: "",
  our_vision_fr: "",
  our_vision_ar: "",
  our_objective_en: "",
  our_objective_fr: "",
  our_objective_ar: "",
  video_url:"",
  project: "",
  partners: "",
  person_inspired: "",
  events: ""
});


  const [previousImageURL, setPreviousImageURL] = useState('');
  const [previousImageURL2, setPreviousImageURL2] = useState('');
  const [previousImageURL3, setPreviousImageURL3] = useState('');
  const [previousImageURLbg, setPreviousImageURLBg] = useState('');

  // submit function to update a new item
  const onEditSubmit = () => {
    setGridLoading(true);
  
    const {
      title_en,
      title_fr,
      title_ar,
      raised_people,
      video_url,
      volunteer,
      years_of_experience,
      projects_completed,
      description_en,
      description_fr,
      description_ar,
      our_mission_en,
      our_mission_fr,
      our_mission_ar,
      our_vision_en,
      our_vision_fr,
      our_vision_ar,
      our_objective_en,
      our_objective_fr,
      our_objective_ar,
      image_one,
      image_two,
      image_three,
      image_video_bg,
      project,
      partners,
      person_inspired,
      events
  } = editFormData;
    const formDataToSend = new FormData();
    formDataToSend.append('title_en', title_en);
    formDataToSend.append('title_fr', title_fr);
    formDataToSend.append('title_ar', title_ar);
    formDataToSend.append('raised_people', raised_people);
    formDataToSend.append('video_url', video_url);
    formDataToSend.append('volunteer', volunteer);
    formDataToSend.append('years_of_experience', years_of_experience);
    formDataToSend.append('projects_completed', projects_completed);
    formDataToSend.append('description_en', description_en);
    formDataToSend.append('description_fr', description_fr);
    formDataToSend.append('description_ar', description_ar);
    formDataToSend.append('our_mission_en', our_mission_en);
    formDataToSend.append('our_mission_fr', our_mission_fr);
    formDataToSend.append('our_mission_ar', our_mission_ar);
    formDataToSend.append('our_vision_en', our_vision_en);
    formDataToSend.append('our_vision_fr', our_vision_fr);
    formDataToSend.append('our_vision_ar', our_vision_ar);
    formDataToSend.append('our_objective_en', our_objective_en);
    formDataToSend.append('our_objective_fr', our_objective_fr);
    formDataToSend.append('our_objective_ar', our_objective_ar);
    formDataToSend.append('project', project);
    formDataToSend.append('partners', partners);
    formDataToSend.append('person_inspired', person_inspired);
    formDataToSend.append('events', events);

  
    if (image_one && image_one instanceof File) {
      formDataToSend.append('image_one', image_one);
    }
    if (image_two && image_two instanceof File) {
      formDataToSend.append('image_two', image_two);
    }
    if (image_three && image_three instanceof File) {
      formDataToSend.append('image_three', image_three);
    }
    if (image_video_bg && image_video_bg instanceof File) {
      formDataToSend.append('image_video_bg', image_video_bg);
    }
  
    AboutService.update(editFormData?.id, formDataToSend)
      .then(res => {
        console.log('About mise à jour avec succès !', res);
        getAll();
        setImagePreview("")
        setPreviousImageURL("")
        setImage2Preview("")
        setImage3Preview("")
        setImageBgPreview("")

      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour de About:', error);
      })
      .finally(() => {
        setGridLoading(false);
      });
  };
  


  useEffect(() => {
    if (data.length > 0) {
      const firstItem = data[0]; 
      setPreviousImageURL(firstItem.image_one);
      setPreviousImageURL2(firstItem.image_two);
      setPreviousImageURL3(firstItem.image_three);
      setPreviousImageURLBg(firstItem.image_video_bg);
      setEditFormData({
        id:firstItem.id,
        title_en: firstItem.title_en,
        title_fr: firstItem.title_fr,
        title_ar: firstItem.title_ar,
        raised_people: firstItem.raised_people,
        video_url: firstItem.video_url,
        volunteer: firstItem.volunteer,
        years_of_experience: firstItem.years_of_experience,
        projects_completed: firstItem.projects_completed,
        description_en: firstItem.description_en,
        description_fr: firstItem.description_fr,
        description_ar: firstItem.description_ar,
        our_mission_en: firstItem.our_mission_en,
        our_mission_fr: firstItem.our_mission_fr,
        our_mission_ar: firstItem.our_mission_ar,
        our_vision_en: firstItem.our_vision_en,
        our_vision_fr: firstItem.our_vision_fr,
        our_vision_ar: firstItem.our_vision_ar,
        our_objective_en: firstItem.our_objective_en,
        our_objective_fr: firstItem.our_objective_fr,
        our_objective_ar: firstItem.our_objective_ar,
        image_one: firstItem.image_one,
        image_two: firstItem.image_two,
        image_three: firstItem.image_three,
        image_video_bg: firstItem.image_video_bg,
        project: firstItem.project,
        partners: firstItem.partners,
        person_inspired: firstItem.person_inspired,
        events: firstItem.events
      });
    }
  }, [data]);
  
  



const { reset, register, handleSubmit, formState: { errors } } = useForm();


  const handleChange = prop => event => {
    setEditFormData({ ...editFormData, [prop]: event.target.value })
  }
  useEffect(() => {
    reset(editFormData);
    if (editFormData?.image_one instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(editFormData?.image_one);
    } else if (typeof editFormData?.image_one === 'string') {
        setImagePreview("");
    }
    if (editFormData?.image_two instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage2Preview(reader.result);
      };
      reader.readAsDataURL(editFormData?.image_two);
      } else if (typeof editFormData?.image_two === 'string') {
        setImage2Preview("");
      }
      if (editFormData?.image_three instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
            setImage3Preview(reader.result);
        };
        reader.readAsDataURL(editFormData?.image_three);
    } else if (typeof editFormData?.image_three === 'string') {
      setImage3Preview("");
    }
    if (editFormData?.image_video_bg instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageBgPreview(reader.result);
      };
      reader.readAsDataURL(editFormData?.image_video_bg);
  } else if (typeof editFormData?.image_video_bg === 'string') {
    setImageBgPreview("");
  }
}, [editFormData]);


  const onDrop = useCallback((acceptedFiles) => {
    setEditFormData({ ...editFormData, image_one: acceptedFiles[0] });
  }, [editFormData, setEditFormData]);

  const onDrop2 = useCallback((acceptedFiles) => {
    setEditFormData({ ...editFormData, image_two: acceptedFiles[0] });
  }, [editFormData, setEditFormData]);
  const onDrop3 = useCallback((acceptedFiles) => {
    setEditFormData({ ...editFormData, image_three: acceptedFiles[0] });
  }, [editFormData, setEditFormData]);
  const onDropBg = useCallback((acceptedFiles) => {
    setEditFormData({ ...editFormData, image_video_bg: acceptedFiles[0] });
  }, [editFormData, setEditFormData]);

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({ onDrop });
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({ onDrop: onDrop2 });
  const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({ onDrop: onDrop3 });
  const { getRootProps: getRootPropsBg, getInputProps: getInputPropsBg } = useDropzone({ onDrop: onDropBg });
  




  return (
    <React.Fragment>
      <Head title="À propos List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> À propos</BlockTitle>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="nk-tb-list is-separate nk-tb-ulist">
          {GridLoading ? (
            <div className="spinner-container">
              <Spinner color="primary" />
            </div>
          ) : (
            <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>

            <BlockHead size="sm">
              <BlockTitle page>Section À propos pour la page d'accueil</BlockTitle>
            </BlockHead>
                  <Col md="4">
                    <Col md="12" className="text-center"> {/* Ajoutez la classe text-center */}
                      {editFormData?.image_video_bg ? (
                        <img src={previousImageURLbg} alt="Current" className="img-preview" />
                      ) : null}
                    </Col>
                  </Col>
                  <Col md="8">
                    <Col md="12" className="text-center" >
                      <div className="form-group">
                      {!imagebgPreview ? (
                        <label className="form-label">
                          {editFormData?.image_video_bg ? "Si vous voulez changer l'image" : "Image"}
                        </label> 
                        ): (
                          null
                        )}
                        <div {...getRootPropsBg()} className="dropzone">
                          <input {...getInputPropsBg()} />
                          {imagebgPreview ? (
                            <img src={imagebgPreview} alt="Preview" className="img-preview" />
                          ) : (
                            <p>
                              Glissez et déposez une image ici, ou cliquez pour sélectionner une image
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Col>
                  <Nav tabs className="justify-content-center">
                      <NavItem>
                          <NavLink
                              className={classnames({ active: activeTab === "1" })}
                              onClick={() => toggle("1")}
                              style={{ cursor: 'pointer' }} 
                          >
                              <ReactCountryFlag countryCode="US" svg style={{ marginRight: '5px' }}/> English
                          </NavLink>
                      </NavItem>
                      <NavItem>
                          <NavLink
                              className={classnames({ active: activeTab === "2" })}
                              onClick={() => toggle("2")}
                              style={{ cursor: 'pointer' }} 
                          >
                              <ReactCountryFlag countryCode="FR" svg style={{ marginRight: '5px' }}/> Francais
                          </NavLink>
                      </NavItem>
                      <NavItem>
                          <NavLink
                              className={classnames({ active: activeTab === "3" })}
                              onClick={() => toggle("3")}
                              style={{ cursor: 'pointer' }}
                          >
                              <ReactCountryFlag countryCode="TN" svg style={{ marginRight: '5px' }}/> Arabic
                          </NavLink>
                      </NavItem>
                  </Nav>

                  
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Notre mission (en)</label>
                          <textarea
                            type="text"
                            {...register('our_mission_en')}
                            value={editFormData.our_mission_en}
                            placeholder="Entrez notre mission. (en)"
                            onChange={handleChange('our_mission_en')}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Notre vision (en)</label>
                          <textarea
                            type="text"
                            {...register('our_vision_en')}
                            value={editFormData.our_vision_en}
                            placeholder="Entrez notre vision. (en)"
                            onChange={handleChange('our_vision_en')}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Notre objectif (en)</label>
                          <textarea
                            type="text"
                            {...register('our_objective_en')}
                            value={editFormData.our_objective_en}
                            placeholder="Entrez notre objectif. (en)"
                            onChange={handleChange('our_objective_en')}
                            className="form-control" />
                        </div>
                      </Col>
                    </TabPane>
                    <TabPane tabId="2">
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Notre mission (fr)</label>
                          <textarea
                            type="text"
                            {...register('our_mission_fr')}
                            value={editFormData.our_mission_fr}
                            placeholder="Entrez notre mission. (fr)"
                            onChange={handleChange('our_mission_fr')}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Notre vision (fr)</label>
                          <textarea
                            type="text"
                            {...register('our_vision_fr')}
                            value={editFormData.our_vision_fr}
                            placeholder="Entrez notre vision. (fr)"
                            onChange={handleChange('our_vision_fr')}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Notre objectif (fr)</label>
                          <textarea
                            type="text"
                            {...register('our_objective_fr')}
                            value={editFormData.our_objective_fr}
                            placeholder="Entrez notre objectif. (fr)"
                            onChange={handleChange('our_objective_fr')}
                            className="form-control" />
                        </div>
                      </Col>
                    </TabPane>
                    <TabPane tabId="3">
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Notre mission (Ar)</label>
                          <textarea
                            type="text"
                            {...register('our_mission_ar')}
                            value={editFormData.our_mission_ar}
                            placeholder="Entrez notre mission. (ar)"
                            onChange={handleChange('our_mission_ar')}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Notre vision (ar)</label>
                          <textarea
                            type="text"
                            {...register('our_vision_ar')}
                            value={editFormData.our_vision_ar}
                            placeholder="Entrez notre vision. (ar)"
                            onChange={handleChange('our_vision_ar')}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Notre objectif (ar)</label>
                          <textarea
                            type="text"
                            {...register('our_objective_ar')}
                            value={editFormData.our_objective_ar}
                            placeholder="Entrez notre objectif. (ar)"
                            onChange={handleChange('our_objective_ar')}
                            className="form-control" />
                        </div>
                      </Col>
                    </TabPane>
                  </TabContent>

                  
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Video Url</label>
                      <textarea
                        type="text"
                        {...register('video_url')}
                        value={editFormData.video_url}
                        placeholder="Entrez votre video url"
                        onChange={handleChange('video_url')}
                        className="form-control" />
                    </div>
                  </Col>
                  {/* section About */}
              <BlockHead size="sm">
                <BlockTitle page>Section À propos</BlockTitle>
              </BlockHead>
                  {/* left page */}
                <Col md="4">
                      <Col md="12">
                        {editFormData?.image_one ? (
                          <img src={previousImageURL} alt="Current" className="img-preview" />
                        ) : null}
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">
                            {editFormData?.image_one ? "Si vous voulez changer l'image" : "Image"}
                          </label>
                          <div {...getRootProps1()} className="dropzone">
                            <input {...getInputProps1()} />
                            {imagePreview ? (
                              <img src={imagePreview} alt="Preview" className="img-preview" />
                            ) : (
                              <p>
                                Glissez et déposez une image ici, ou cliquez pour sélectionner une image
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col md="12">
                        {editFormData?.image_two ? (
                          <img src={previousImageURL2} alt="Current" className="img-preview" />
                        ) : null}
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">
                            {editFormData?.image_two ? "Si vous voulez changer l'image" : "Image"}
                          </label>
                          <div {...getRootProps2()} className="dropzone">
                            <input {...getInputProps2()} />
                            {image2Preview ? (
                              <img src={image2Preview} alt="Preview" className="img-preview" />
                            ) : (
                              <p>
                                Glissez et déposez une image ici, ou cliquez pour sélectionner une image
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md="12">
                        {editFormData?.image_three ? (
                          <img src={previousImageURL3} alt="Current" className="img-preview" />
                        ) : null}
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">
                            {editFormData?.image_three ? "Si vous voulez changer l'image" : "Image"}
                          </label>
                          <div {...getRootProps3()} className="dropzone">
                            <input {...getInputProps3()} />
                            {image3Preview ? (
                              <img src={imagePreview} alt="Preview" className="img-preview" />
                            ) : (
                              <p>
                                Glissez et déposez une image ici, ou cliquez pour sélectionner une image
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>
                </Col>
                  {/* Right page */}
                <Col md="8">
                {/* <Nav tabs className="justify-content-center">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTabA === "1" })}
                      onClick={() => toggle("1")}
                    >
                      Engish
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTabA === "2" })}
                      onClick={() => toggle("2")}
                    >
                      Fr
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTabA === "3" })}
                      onClick={() => toggle("3")}
                    >
                      Ar
                    </NavLink>
                  </NavItem>
                </Nav> */}
                <TabContent activeTab={activeTabA}>
                    <TabPane tabId="1">
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Titre (En)</label>
                          <input
                            type="text"
                            value={editFormData?.title_en}
                            placeholder="Entrez le titre (en)"
                            onChange={handleChange("title_en")}
                            className="form-control"
                          />
                          {errors.title_en && <span className="invalid">{errors.title_en.message}</span>}
                        </div>
                      </Col>
                      {/* Description (en) */}
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label"  style={{marginTop:"5px"}}>Description (en)</label>
                          <textarea
                            type="text"
                            value={editFormData?.description_en}
                            placeholder="Entrez la description (en)"
                            onChange={handleChange("description_en")}
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </TabPane>
                    <TabPane tabId="2">
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Titre (Fr)</label>
                          <input
                            type="text"
                            value={editFormData?.title_fr}
                            placeholder="Entrez le titre (fr)"
                            onChange={handleChange("title_fr")}
                            className="form-control"
                          />
                          {errors.title_fr && <span className="invalid">{errors.title_fr.message}</span>}
                        </div>
                      </Col>
                      {/* Description (fr) */}
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label"  style={{marginTop:"5px"}}>Description (Fr)</label>
                          <textarea
                            type="text"
                            value={editFormData?.description_fr}
                            placeholder="Entrez la description (fr)"
                            onChange={handleChange("description_fr")}
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </TabPane>
                    <TabPane tabId="3">
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label">Titre (Ar)</label>
                          <input
                            type="text"
                            value={editFormData?.title_ar}
                            placeholder="Entrez le titre (ar)"
                            onChange={handleChange("title_ar")}
                            className="form-control"
                          />
                          {errors.title_ar && <span className="invalid">{errors.title_ar.message}</span>}
                        </div>
                      </Col>
                      {/* Description (ar) */}
                      <Col md="12">
                        <div className="form-group">
                          <label className="form-label"  style={{marginTop:"5px"}}>Description (Ar)</label>
                          <textarea
                            type="text"
                            value={editFormData?.description_ar}
                            placeholder="Entrez la description (ar)"
                            onChange={handleChange("description_ar")}
                            className="form-control"
                          />
                        </div>
                      </Col>
                    </TabPane>
                   </TabContent>
                  <div class="container">
                    <div class="row">
                      <div class="col">
                          <Col md="12">
                            <div className="form-group">
                              <label className="form-label" style={{marginTop:"5px"}}>Personnes élevées</label>
                              <input
                                type="number"
                                value={editFormData?.raised_people}
                                placeholder="Entrez les personnes élevées"
                                onChange={handleChange("raised_people")}
                                className="form-control"
                              />
                              {errors.raised_people && <span className="invalid">{errors.raised_people.message}</span>}
                            </div>
                          </Col>
                          <Col md="12">
                            <div className="form-group">
                              <label className="form-label"  style={{marginTop:"5px"}}>Bénévole</label>
                              <input
                                type="number"
                                value={editFormData?.volunteer}
                                placeholder="Entrez les bénévoles"
                                onChange={handleChange("volunteer")}
                                className="form-control"
                              />
                              {errors.volunteer && <span className="invalid">{errors.volunteer.message}</span>}
                            </div>
                          </Col>
                        </div>
                        <div class="col">
                          <Col md="12" >
                            <div className="form-group">
                              <label className="form-label"  style={{marginTop:"5px"}}>Années d'expérience</label>
                              <input
                                type="number"
                                value={editFormData?.years_of_experience}
                                placeholder="Entrez les années d'expérience"
                                onChange={handleChange("years_of_experience")}
                                className="form-control"
                              />
                              {errors.years_of_experience && <span className="invalid">{errors.years_of_experience.message}</span>}
                            </div>
                          </Col>
                          <Col md="12">
                            <div className="form-group">
                              <label className="form-label"  style={{marginTop:"5px"}}>Projets terminés</label>
                              <input
                                type="number"
                                value={editFormData?.projects_completed}
                                placeholder="Entrez les Projets terminés"
                                onChange={handleChange("projects_completed")}
                                className="form-control"
                              />
                              {errors.projects_completed && <span className="invalid">{errors.projects_completed.message}</span>}
                            </div>
                          </Col>
                        </div>
                    </div>
                  </div>
                
                </Col>
            

                <BlockHead size="sm">
                <BlockTitle page>Paramètres de la page d'accueil</BlockTitle>
              </BlockHead>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Projet</label>
                  <input
                    type="text"
                    value={editFormData?.project}
                    placeholder="Enter program details"
                    onChange={handleChange("project")}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Les partenaires</label>
                  <input
                    type="text"
                    value={editFormData?.partners}
                    placeholder="Enter partners details"
                    onChange={handleChange("partners")}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Personne inspirée</label>
                  <input
                    type="text"
                    value={editFormData?.person_inspired}
                    placeholder="Enter person inspired details"
                    onChange={handleChange("person_inspired")}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Événements</label>
                  <input
                    type="text"
                    value={editFormData?.events}
                    placeholder="Enter events details"
                    onChange={handleChange("events")}
                    className="form-control"
                  />
                </div>
              </Col>

                <Col size="12" >
                  <ul className="text-end flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button color="primary" size="md" type="submit" disabled={GridLoading}>
                        {GridLoading ? <Spinner size="sm" color="light" /> : "Modifier À propos"}
                      </Button>
                    </li>
                  </ul>
                </Col>
          </Form>
          
          )}
          </div>
       
        </Block>


      </Content>
      
    </React.Fragment>

    
  );
};

export default AboutList;