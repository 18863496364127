function server(){
    return process.env.REACT_APP_API_URL;
}

export const ApiConfigs = {
    base_url: server()+'/admin/',
    /* -------------------------------- */
//    User 

    admin: {
        login : 'login',
        logout: 'logout',
    },
    category: {
        getAll: 'categories',
        getById: 'categories/{id}',
        create: 'categories',
        update: 'categories/{id}',
        delete: 'categories/{id}',
    },
    categoryDon: {
        getAll: 'categories-don',
        getById: 'categories-don/{id}',
        create: 'categories-don',
        update: 'categories-don/{id}',
        delete: 'categories-don/{id}',
    },
    advertisement: {
        getAll: 'advertisements',
        getById: 'advertisements/{id}',
        create: 'advertisements',
        update: 'advertisements/{id}',
        delete: 'advertisements/{id}',
    },
    advertisementDons: {
        getAll: 'advertisements-dons',
        getById: 'advertisements-dons/{id}',
        create: 'advertisements-dons',
        update: 'advertisements-dons/{id}',
        delete: 'advertisements-dons/{id}',
    },
    blogs: {
        getAll: 'blogs',
        getById: 'blogs/{id}',
        create: 'blogs',
        update: 'blogs/{id}',
        delete: 'blogs/{id}',
    },
    siders: {
        getAll: 'slider',
        getById: 'slider/{id}',
        create: 'slider',
        update: 'slider/{id}',
        delete: 'slider/{id}',
    },
    partner: {
        getAll: 'partner',
        getById: 'partner/{id}',
        create: 'partner',
        update: 'partner/{id}',
        delete: 'partner/{id}',
    },
    project: {
        getAll: 'projects',
        getById: 'projects/{id}',
        create: 'projects',
        update: 'projects/{id}',
        delete: 'projects/{id}',
        getGallery: 'projects/{id}/galerie',
        storeGallery: 'projects/store/{id}/galerie',
        deleteGalleryImage: 'projects/{projectId}/galerie/{galleryId}',
        getReports: 'projects/{id}/reports',
        storeReport: 'projects/{id}/reports',
        deleteReport: 'projects/{projectId}/reports/{reportId}',
    },
    about: {
        getAll: 'about',
        update: 'about/{id}',
    },
    generalSettings: {
        getAll: 'general-settings',
        update: 'general-settings/{id}',
    },
    contact: {
        getAll: 'contact',
        getById: 'contact/{id}',
    },
    statistique: {
        getAll: 'statistique',
    },
    comments: {
        getAll: 'comments',
        getById: 'comments/{blog_id}',
    },
    groupslider: {
        getAll: 'groupslider',
        getById: 'groupslider/{id}',
        create: 'groupslider',
        update: 'groupslider/{id}',
        delete: 'groupslider/{id}',
    },
    files: {
        getAll: 'files',
        getById: 'files/{id}',
        create: 'files',
        update: 'files/{id}',
        delete: 'files/{id}',
    },
    donations: {
        getAll: 'donations',
        getById: 'donations/{id}',
    },
    users: {
        getAll: 'user',
        getById: 'user/{id}',
    },


};