import React from "react";
import {
  Icon,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import moment from "moment";

const ContactDetails = ({ modal, closeModal, contact, loading }) => {
  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          contact && (
            <>
              <Row>
                <Col md="12">
                  <h3>Details de Contact</h3>
                </Col>
                <br />
                <br />
                <br />
                <Col md="6">
                  <span className="sub-text">Nom</span>
                  <span className="caption-text">{contact.name}</span>
                </Col>
                <Col md="6">
                  <span className="sub-text">E-mail</span>
                  <span className="caption-text">{contact.email}</span>
                </Col>
                <Col md="6">
                  <span className="sub-text">Numéro de téléphone</span>
                  <span className="caption-text">{contact.phone}</span>
                </Col>
                <hr />
                <Col md="12">
                  <span className="sub-text">Message</span>
                  <span className="caption-text">{contact.message}</span>
                </Col>
                <hr />
                <Col md="6">
                  <span className="sub-text">Date de naissance</span>
                  <span>{contact?.birth_date ? moment(contact.birth_date).format('DD MMM YYYY') : '--'}</span>
                </Col>

                <Col md="6">
                  <span className="sub-text">Référence</span>
                  <span className="caption-text">{contact.ref == 'advertisements'? 'Annonce' : 'Contact'}</span>
                </Col>
                {contact?.related_data?.title_fr && (
                  <Col md="6">
                    <span className="sub-text">Titre de l'annonce</span>
                    <span className="caption-text">{contact.related_data.title_fr}</span>
                  </Col>
                )}
                <Col md="6">
                  <span className="sub-text">Créé à</span>
                  <span>{contact?.created_at ? moment(contact.created_at).format('DD MMM YYYY') : '--'}</span>
                </Col>
              </Row>
            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default ContactDetails;
