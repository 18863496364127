import React, { useEffect, useState } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import DataCard from "../components/partials/default/DataCard";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  BlockBetween,
} from "../components/Component";
import { StatistiqueService } from "../_services/statistique.service";
import Advertisements from "../images/illustrations/Advertisements.png";
import Categorie from "../images/illustrations/Categorie.png";
import Project from "../images/illustrations/Project.png";
import Blog from "../images/illustrations/Blog.png";
import Slider from "../images/illustrations/Slider.png";
import Partner from "../images/illustrations/Partner.png";
import About from "../images/illustrations/About.png";
import Contact from "../images/illustrations/Contact.png";
import Donation from "../images/illustrations/donation.png";
import File from "../images/illustrations/file.png";
import GroupeSlider from "../images/illustrations/groupe_slider.png";
import Settings  from "../images/illustrations/settings.png";

const Homepage = () => {
  const [sm, updateSm] = useState(false);

  const [GridLoading, setGridLoading] = useState(false)
  const [data, setData] = useState([]);

  const getAll = () => {
    setGridLoading(true)
    StatistiqueService.getAll()
      .then(res => {
        setData(res.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setGridLoading(false)
      })
  }

  useEffect(() => {
    getAll()
  }, [])

  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                  Tableau de bord
              </BlockTitle>
            </BlockHeadContent>
            {/* <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                          <Icon className="d-none d-sm-inline" name="calender-date" />
                          <span>
                            <span className="d-none d-md-inline">Last</span> 30 Days
                          </span>
                          <Icon className="dd-indc" name="chevron-right" />
                        </DropdownToggle>
                        <DropdownMenu end>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                href="#!"
                              >
                                <span>Last 30 days</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                href="#dropdownitem"
                              >
                                <span>Last 6 months</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                                href="#dropdownitem"
                              >
                                <span>Last 3 weeks</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button color="primary">
                        <Icon name="reports" />
                        <span>Reports</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent> */}
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            {/* 
          <Col   xxl={3} sm={6}>
                
                <Card className="card-bordered">   
              <CardImg top src="images/avatar/b-sm.jpg" alt="" />
              <CardBody className="card-inner">
                <CardTitle tag="h5">Card with stretched link</CardTitle>
                <CardText>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </CardText>
                <Button color="primary">Go somewhere</Button>
              </CardBody>
            </Card> 
          </Col>
            */}
            <Col xxl={12} sm={12}>
              <DataCard
                title={"Program"}
                description={"Total des projets affichés sur le site Web"}
                number={data.Project?.total}
                number_name={"Programs"}
                button_text={"Gérer les projets"}
                href={"/projects"}
                illustration={Project}
                loading={GridLoading}
              />
            </Col>

            <Col xxl={4} lg={4} sm={6}>
              <DataCard
                title={"Catégoies des Dons"}
                description={"Nombre de catégories de dons."}
                number={data.CategorieDon?.total}
                number_name={"catégories de dons"}
                button_text={"Gerér les Categorie Don"}
                href={"/categories-dons-liste"}
                illustration={Advertisements}
                loading={GridLoading}
              />
            </Col>
            <Col xxl={4} lg={4} sm={6}>
              <DataCard
                title={"Dons"}
                description={" Nombre des postes de dons postulé."}
                number={data.AdvertisementsDons?.total}
                number_name={"Postes de Dons"}
                button_text={"Gerér les Advertisements Dons"}
                href={"/advertisements-dons-list"}
                illustration={Categorie}
                loading={GridLoading}
              />
            </Col>
            <Col xxl={4} lg={4} sm={6}>
              <DataCard
                title={"Transactions des Dons"}
                description={"The donations given by the Volunteers"}
                number={data.Donations?.total}
                number_name={"Transaction des Dons"}
                button_text={"Consulter les Dons données"}
                href={"/donation"}
                illustration={Donation}
                loading={GridLoading}
              />
            </Col>
   
            <Col xxl={3} sm={6}>
              <DataCard
                title={"Catégories des Annonces ( Get Involved )"}
                description={"Nombre de catégories d'annonces."}
                number={data.CategorieDon?.total}
                number_name={"catégories d'annonces"}
                button_text={"Gerér les Categorie d'annonces"}
                href={"/categories-liste"}
                illustration={Categorie}
                loading={GridLoading}
              />
            </Col>
            <Col xxl={3} sm={6}>
              <DataCard
                title={"Annonces"}
                description={" Nombre des postes de dons postulé."}
                number={data.AdvertisementsDons?.total}
                number_name={"Postes de Dons"}
                button_text={"Gerér les Advertisements Dons"}
                href={"/advertisements-list"}
                illustration={Advertisements}
                loading={GridLoading}
              />
            </Col>
            

            <Col xxl={3} sm={6}>
              <DataCard
                title={"Blogs / Actualités"}
                description={"Nombre total des poste d'actualité en cours"}
                number={data.Blog?.total}
                number_name={"Blogs"}
                button_text={"Gérer les articles de blog"}
                href={"/blog"}
                illustration={Blog}
                loading={GridLoading}
              />
            </Col>
            <Col xxl={3} sm={6}>
              <DataCard
                title={"Slider"}
                description={"Nombre de sliders / Les images qui apparaitre en premier dans la page d'accueil."}
                number={data.Slider?.total}
                number_name={"Sliders"}
                button_text={"Gérer les sliders"}
                href={"/slider"}
                illustration={Slider}
                loading={GridLoading}
              />
            </Col>
            <Col xxl={3} sm={6}>
              <DataCard
                title={"Partners"}
                description={"Nombre de partenaires."}
                number={data.Partner?.total}
                number_name={"Partners"}
                button_text={"Gérer les partenaires"}
                href={"/partner"}
                illustration={Partner}
                loading={GridLoading}
              />
            </Col>
            <Col xxl={3} sm={6}>
              <DataCard
                title={"À propos"}
                description={"Informations sur Weyouth pour afficher dans la plateforme."}
                number={"--"}
                number_name={"À propos"}
                button_text={"Voir À propos"}
                href={"/about"}
                illustration={About}
                loading={GridLoading}
              />
            </Col>
            <Col xxl={3} sm={6}>
              <DataCard
                title={"Contact"}
                description={"Liste des utilisateurs qui vous ont contacté à partir des formulaires du site."}
                number={"--"}
                number_name={"Contact"}
                button_text={"Voir les contacts"}
                href={"/contact"}
                illustration={Contact}
                loading={GridLoading}
              />
            </Col>
          <Col xxl={3} sm={6}>
              <DataCard
                  title={"Collaborateurs"}
                  description={"Nombre desCollaborateurs."} 
                  number={data.Groupe_slider?.total}
                  number_name={"Collaborateurs"} 
                  button_text={"Gérer les Collaborateurs"} 
                  href={"/groupe-slider"}
                  illustration={GroupeSlider}
                  loading={GridLoading}
              />
          </Col>
          <Col xxl={3} sm={6}>
              <DataCard
                  title={"Fichier"}
                  description={"Nombre de Fichiers dans la libreries."} 
                  number={data.fichier?.total}
                  number_name={"Fichier"} 
                  button_text={"Gérer les Fichier"} 
                  href={"/file"}
                  illustration={File}
                  loading={GridLoading}
              />
          </Col>
          <Col xxl={3} sm={6}>
              <DataCard
                  title={"Paramètres généraux"}
                  description={"Réglages des paramètres."} 
                  number={""}
                  number_name={"--"} 
                  button_text={"Gérer les Paramètres généraux"} 
                  href={"/general-settings"}
                  illustration={Settings}
                  loading={GridLoading}
              />
          </Col>
            {/* <Col xxl="6">
              <SalesStatistics />
            </Col>
            <Col xxl="3" md="6">
              <OrderStatistics />
            </Col>
            <Col xxl="3" md="6">
              <StoreStatistics />
            </Col> */}
            {/* <Col xxl="8">
              <RecentOrders />
            </Col> */}
            {/* <Col xxl="4" md="8" lg="6">
              <TopProducts />
            </Col> */}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;
