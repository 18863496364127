import React  from "react";
import {
  Icon,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import moment from "moment";

const UserDetails = ({modal, closeModal,user,loading}) => {


  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" className="close">
          {" "}
          <Icon
            name="cross-sm"
            onClick={(ev) => {
              ev.preventDefault();
              closeModal();
            }}
          ></Icon>
        </a>
        {loading ? (
          <p>Chargement en cours...</p>
        ) : (
          user && (
            <>
           
           
           <Row>
              <Col md="12">
                <h3>Détails de l'utilisateur</h3>
              </Col>
              <br />
              <br />
              <br />
              <Col md="6">
                <span className="sub-text">Nom</span>
                <span className="caption-text">{user.name}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">E-mail</span>
                <span className="caption-text">{user.email}</span>
              </Col>
              <Col md="6">
                <span className="sub-text">Créé à</span>
                <span>{user?.created_at ? moment(user.created_at).format('DD MMM YYYY') : '--'}</span>
              </Col>
            </Row>

            </>
          )
        )}
      </ModalBody>
    </Modal>
  );
};

export default UserDetails;
