import React, { useEffect } from "react";
import {
  Icon,
  Button,
  Col
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Form,
  Spinner
} from "reactstrap";

const DeleteModal = ({modal, closeModal, onSubmit,GridLoading}) => {


  const confirmDelete = (ev) => {
    ev.preventDefault(); 
    onSubmit(); 
};
  
  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        {GridLoading ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
        <div className="p-2">
          <h5 className="title">Es-tu sûr de vouloir supprimer cette fichier.</h5>
          <div className="mt-4">
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                  <Button color="primary" size="md" type="submit" onClick={confirmDelete}>
                    {GridLoading ?
                       <Spinner size="sm" color="light" />  
                       :
                       "Supprimer ce programme"
                     }
                    
                  </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Annuler
                    </Button>
                  </li>
                </ul>
              </Col>
          </div>
        </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
