import React, { useEffect, useState, useCallback } from "react";
import { Icon, Button, Col } from "../../../components/Component";
import { Modal, ModalBody, Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { useDropzone } from 'react-dropzone';

const FormModal = ({modal, closeModal, onSubmit, formData, setFormData, modalType, GridLoading, file, setFile }) => {
  useEffect(() => {
    reset(formData);
  }, [formData, file]); 

  const { reset, register, handleSubmit, formState: { errors } } = useForm();

  const handleChange = prop => event => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    setFile(file);
    setFormData({ ...formData, path: file.name });
  }, [formData, setFile, setFormData]);

  const {getRootProps, getInputProps} = useDropzone({onDrop});

  return (
    <Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a href="#cancel" onClick={(ev) => { ev.preventDefault(); closeModal(); }} className="close">
          <Icon name="cross-sm"></Icon>
        </a>
        {GridLoading ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
        <div className="p-2">
          <h5 className="title">{modalType === "add" ? "Ajouter un fichier" : "Modifier le fichier"}</h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Fichier</label>
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} accept=".pdf" disabled={!!file} />
                    <p>
                      {file ? (
                        <p>Fichier sélectionné: {file.name}</p>
                      ) : (
                        <p>
                          {modalType === "edit" && formData.path && (
                            <>
                              <p>Fichier actuel : {formData?.path.split('/').pop()}</p> <hr />
                            </>
                          )}
                          Glissez et déposez un fichier ici, ou cliquez pour sélectionner un fichier
                        </p>
                      )}
                    </p>
                  </div>
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Nom du fichier</label>
                  <input
                    type="text"
                    {...register('name', { required: "Ce champ est requis" })}
                    value={formData.name}
                    placeholder="Entrez le Nom"
                    onChange={handleChange('name')}
                    className="form-control" />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Priorité</label>
                  <input
                    type="number"
                    {...register('priority')}
                    value={formData.priority}
                    placeholder="Entrez la priorité"
                    onChange={handleChange('priority')}
                    className="form-control" />
                </div>
              </Col>

              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Description courte (En)</label>
                  <textarea
                    type="text"
                    {...register('short_desc_en')}
                    value={formData.short_desc_en}
                    placeholder="Entrez la description courte"
                    onChange={handleChange('short_desc_en')}
                    className="form-control" />
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Description courte (Fr)</label>
                  <textarea
                    type="text"
                    {...register('short_desc_fr')}
                    value={formData.short_desc_fr}
                    placeholder="Entrez la description courte"
                    onChange={handleChange('short_desc_fr')}
                    className="form-control" />
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Description courte (Ar)</label>
                  <textarea
                    type="text"
                    {...register('short_desc_ar')}
                    value={formData.short_desc_ar}
                    placeholder="Entrez la description courte"
                    onChange={handleChange('short_desc_ar')}
                    className="form-control" />
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {GridLoading ?
                        <Spinner size="sm" color="light" />
                        :
                        modalType === "add" ? "Ajouter un fichier" : "Modifier le fichier"
                      }
                    </Button>
                  </li>
                  <li>
                    <Button onClick={(ev) => { ev.preventDefault(); closeModal(); }} className="link link-light">
                      Annuler
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default FormModal;
