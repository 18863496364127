import React, { useState, useEffect  } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Badge,
  Spinner,
} from "reactstrap";
import Skeleton  from 'react-loading-skeleton';
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  UserAvatar,
  PaginationComponent,
  PreviewAltCard,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import { findUpper, setDeadline, setDeadlineDays} from "../../../utils/Utils";
import FormModal from "./FormModal";
import { CategoryService } from "../../../_services/category.service ";
import DeleteModal from "./DeleteModal";
import moment from "moment";
import CategoriesDetails from "./CategoriesDetails";

export const CategoriesList = () => {
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    delete :false,
    details: false,
  });
  const [editId, setEditedId] = useState();
  const [deleteId, setDeletedId] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [GridLoading, setGridLoading] = useState(false)

  const getCategories = () => {
    setGridLoading(true)
    CategoryService.getAllCategories()
      .then(res => {
        setData(res.data)
        setFilteredData(res.data);
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setGridLoading(false)
      })
  }
  useEffect(() => {
    getCategories()
  }, [])
const [formData, setFormData] = useState({
  name_en: "",
  name_fr: "",
  name_ar: "",
  seo_title_en: "",
  seo_title_fr: "",
  seo_title_ar: "",
  seo_description_en: "",
  seo_description_fr: "",
  seo_description_ar: "",
  seo_keywords: "",
});

const [editFormData, setEditFormData] = useState({
  name_en: "",
  name_fr: "",
  name_ar: "",
  seo_title_en: "",
  seo_title_fr: "",
  seo_title_ar: "",
  seo_description_en: "",
  seo_description_fr: "",
  seo_description_ar: "",
  seo_keywords: "",
});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(7);

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name_en: "",
      name_fr: "",
      name_ar: "",
      seo_title_en: "",
      seo_title_fr: "",
      seo_title_ar: "",
      seo_description_en: "",
      seo_description_fr: "",
      seo_description_ar: "",
      seo_keywords: "",
    });
  };

  const closeModal = () => {
    setModal({ add: false })
    resetForm();
  };

  const closeEditModal = () => {
    setModal({ edit: false })
    resetForm();
  };
  const closeDeleteModal = () => {
    setModal({ delete: false })
    resetForm();
  };
  // submit function to add a new item
  const [loadingAdd, setLoadingAdd] = useState(false)

  const onFormSubmit = () => {    
    setLoadingAdd(true); 
    CategoryService.createCategory(formData)
      .then(res => {
        getCategories();
        resetForm();
        setModal({ add: false });
      })
      .catch(error => {
        console.error('Erreur lors de la soumission de la catégorie :', error);
      })
      .finally(() => {
        setLoadingAdd(false); 
      });
  };
  
  

  // submit function to update a new item
  const onEditSubmit = (sData) => {
    const { id, name_en, name_fr, name_ar, seo_title_en, seo_title_fr, seo_title_ar, seo_description_en, seo_description_fr, seo_description_ar, seo_keywords } = sData; // Assurez-vous d'avoir l'ID de la catégorie et toutes les autres données nécessaires
    const updatedData = {
      name_en: name_en,
      name_fr: name_fr,
      name_ar: name_ar,
      seo_title_en: seo_title_en,
      seo_title_fr: seo_title_fr,
      seo_title_ar: seo_title_ar,
      seo_description_en: seo_description_en,
      seo_description_fr: seo_description_fr,
      seo_description_ar: seo_description_ar,
      seo_keywords: seo_keywords,
    };
    setLoadingAdd(true);
    CategoryService.updateCategory(editId, updatedData)
      .then(res => {
        console.log('Catégorie mise à jour avec succès !', res);
        getCategories(); 
        setModal({ edit: false });
        resetForm(); 
      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour de la catégorie :', error);
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };

  const onEditClick = (id) => {
    data.forEach((item) => {
      if (item.id === id) {
        setEditFormData({
          name_en: item.name_en,
          name_fr: item.name_fr,
          name_ar: item.name_ar,
          seo_title_en: item.seo_title_en,
          seo_title_fr: item.seo_title_fr,
          seo_title_ar: item.seo_title_ar,
          seo_description_en: item.seo_description_en,
          seo_description_fr: item.seo_description_fr,
          seo_description_ar: item.seo_description_ar,
          seo_keywords: item.seo_keywords,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(id);
      }
    });
  };

  const onDeleteClick = (id) => {
    setModal({ delete: true }, { add: false },{ edit: false },{details : false });
    setDeletedId(id);
  };

  const [categories, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  const onViewClick = (id) => {
    setModal({details : true },{ delete: false }, { add: false },{ edit: false });
    setLoading(true);
    CategoryService.getById(id)
      .then((res) => {
        setCategory(res.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des détails de Categorie:", error);
      })
      .finally(() => {
        setLoading(false);
      });
      
  };
  const confirmDeleteCategory = () => {
    setGridLoading(true); 
    
    CategoryService.deleteCategory(deleteId)
      .then(() => {
        getCategories(); // Rafraîchir la table après la suppression
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de la catégorie :', error);
      })
      .finally(() => {
        setGridLoading(false); 
        closeDeleteModal(); 
      });
  };
  

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    setGridLoading(true); 
    
    const filtered = data.filter(item =>
      (item.name_fr && item.name_fr.toLowerCase().includes(keyword)) ||
      (item.short_description_fr && item.short_description_fr.toLowerCase().includes(keyword)) ||
      (item.seo_title_fr && item.seo_title_fr.toLowerCase().includes(keyword)) ||
      (item.seo_keywords && item.seo_keywords.toLowerCase().includes(keyword)) ||
      (item.created_at && moment(item.created_at).format('DD MMM YYYY').toLowerCase().includes(keyword))
    );
    setFilteredData(filtered);
    
    setGridLoading(false); 
  };
  

  return (
    <React.Fragment>
      <Head title="Categories List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> Categories</BlockTitle>
              <BlockDes className="text-soft">Vous avez au total {data.length} catégories.</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                  <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="search"
                          // onChange={(e) => onFilterChange(e)}
                          onChange={handleSearch}
                        />
                      </div>
                    </li>
                    <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                      <Button color="primary">
                        <Icon name="plus"></Icon>
                        <span>Ajouter des catégories</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="nk-tb-list is-separate nk-tb-ulist">
            <DataTableHead className="nk-tb-item nk-tb-head">
              <DataTableRow>
                <span className="sub-text">Nom de la catégorie</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Mots-clés de référencement</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Titre SEO</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text">Description SEO</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Créé à</span>
              </DataTableRow>
              
              <DataTableRow className="nk-tb-col-tools text-end">
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="btn btn-xs btn-trigger btn-icon dropdown-toggle me-n1">
                  </DropdownToggle>
                 
                </UncontrolledDropdown>
              </DataTableRow>
            </DataTableHead>
            {GridLoading ? (
                <DataTableItem className={'text-center height-100'}>
                    <DataTableRow className={'spinner-container'}>
                      <Spinner color="primary" />
                    </DataTableRow>
                 </DataTableItem>
            ) : 
            currentItems.length > 0
              ? currentItems.map((item) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow>
                            <span className="title">{item?.name_fr ||'--'}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{item?.seo_title_fr || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="md1">
                        <span>{item?.seo_description_fr || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                         <span>{item?.seo_keywords || '--'}</span>
                      </DataTableRow>
                      <DataTableRow size="mb">
                      <span>{item?.created_at ? moment(item.created_at).format('DD MMM YYYY') : '--'}</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#view"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        onViewClick(item.id);
                                      }}
                                    >
                                      <Icon name="eye"></Icon>
                                      <span>Voir la catégorie </span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => onEditClick(item.id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Modifier</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => onDeleteClick(item.id)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#delete"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                     <Icon name="trash"></Icon>
                                      <span>Supprimer</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              : null}
          </div>
          <PreviewAltCard>
            {filteredData.length > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={data.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">Aucune catégorie trouvée</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>

      
        <FormModal modal={modal.add} modalType="add" formData={formData} setFormData={setFormData} closeModal={closeModal} onSubmit={onFormSubmit} GridLoading={loadingAdd} />
        <FormModal modal={modal.edit} modalType="edit" formData={editFormData} setFormData={setEditFormData} closeModal={closeEditModal} onSubmit={onEditSubmit} GridLoading={loadingAdd} />
        <DeleteModal modal={modal.delete}  closeModal={closeDeleteModal} onSubmit={confirmDeleteCategory} GridLoading={GridLoading} />
        <CategoriesDetails modal={modal.details}  closeModal={closeDeleteModal} categories={categories} loading={loading} />
      </Content>
      
    </React.Fragment>

    
  );
};

export default CategoriesList;
