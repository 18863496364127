import React, { useCallback, useEffect, useState } from "react";
import {
  Icon,
  Button,
  Col,
} from "../../../components/Component";
import {
  Modal,
  ModalBody,
  Form,
  Spinner
} from "reactstrap";
import { useForm } from "react-hook-form";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDropzone } from 'react-dropzone';

const FormModal = ({modal, closeModal, onSubmit, formData, setFormData, modalType,setImagePreview,imagePreview,previousImageURL,GridLoading,ErreurPriority}) => {
  useEffect(() => {
    reset(formData)
  }, [formData]);

  const { reset, register, handleSubmit, formState: { errors } } = useForm();


  const handleChange = prop => event => {
    setFormData({ ...formData, [prop]: event.target.value })
  }
  useEffect(() => {
    reset(formData);
    if (formData.logo instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(formData.logo);
    } else if (typeof formData.logo === 'string') {
        setImagePreview("");
    }
}, [formData]);


  const onDrop = useCallback((acceptedFiles) => {
    setFormData({ ...formData, logo: acceptedFiles[0] });
  }, [formData, setFormData]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  
  return (
<Modal isOpen={modal} toggle={closeModal} className="modal-dialog-centered" size="lg">
  <ModalBody>
    <a
      href="#cancel"
      onClick={(ev) => {
        ev.preventDefault();
        closeModal();
      }}
      className="close"
    >
      <Icon name="cross-sm"></Icon>
    </a>
    {GridLoading ? (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    ) : (
    <div className="p-2">
      <h5 className="title">{modalType === "add" ? "Ajouter un Partner" : "Modifier le Partner"}</h5>
      <div className="mt-4">
        <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
        {modalType === "edit" &&
        <Col md="12">
          { formData.logo ? 
            <img src={previousImageURL} alt="Current" className="img-preview" /> : null }
        </Col>
        }
        <Col md="12">
            <div className="form-group">
            <label className="form-label">{modalType === "edit" ? 'Si vous voulez changer l\'image' : 'Image'}</label>
                <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    {imagePreview ? (
                        <img src={imagePreview} alt="Preview" className="img-preview" />
                    ) : (
                        <p>Glissez et déposez une image ici, ou cliquez pour sélectionner une image</p>
                    )}
                </div>
            </div>
        </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Nom (en)</label>
              <input
                type="text"
                {...register('name_en', { required: "Ce champ est requis" })}
                value={formData.name_en}
                placeholder="Entrez le Nom (en)"
                onChange={handleChange('name_en')}
                className="form-control" />
              {errors.name_en && <span className="invalid">{errors.name_en.message}</span>}
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Nom (fr)</label>
              <input
                type="text"
                {...register('name_fr', { required: "Ce champ est requis" })}
                value={formData.name_fr}
                placeholder="Entrez le Nom (fr)"
                onChange={handleChange('name_fr')}
                className="form-control" />
              {errors.name_fr && <span className="invalid">{errors.name_fr.message}</span>}
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Nom (ar)</label>
              <input
                type="text"
                {...register('name_ar', { required: "Ce champ est requis" })}
                value={formData.name_ar}
                placeholder="Entrez le Nom (ar)"
                onChange={handleChange('name_ar')}
                className="form-control" />
              {errors.name_ar && <span className="invalid">{errors.name_ar.message}</span>}
            </div>
          </Col>
          <hr />
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Url</label>
              <input
                type="text"
                {...register('url')}
                value={formData.url}
                placeholder="Entrez votre URL"
                onChange={handleChange('url')}
                className="form-control" />
            </div>
          </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label">Priority</label>
            <input
              type="number"
              {...register('priority')}
              value={formData.priority}
              placeholder="Entrez la priorité"
              onChange={handleChange('priority')}
              className={`form-control ${ErreurPriority ? 'is-invalid' : ''}`} 
            />
            {ErreurPriority && (
              <div className="invalid-feedback">{ErreurPriority}</div> 
            )}
          </div>
        </Col>
        <hr />
          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
              <li>
              <Button color="primary" size="md" type="submit" disabled={GridLoading}>
                  {GridLoading ?
                      <Spinner size="sm" color="light" />
                      :
                      (modalType === "add" ? "Ajouter un Partner" : "Modifier le Partner")
                  }
              </Button>
              </li>
              <li>
                <Button
                  onClick={(ev) => {
                    ev.preventDefault();
                    closeModal();
                  }}
                  className="link link-light"
                >
                  Annuler
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </div>
    </div>
    )}
  </ModalBody>
</Modal>

  );
};

export default FormModal;
